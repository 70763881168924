import Vue from 'vue'
import moment from 'moment'
import {first} from "lodash";

Vue.filter('formatDate', function (value, formatString) {
  formatString = formatString || 'YYYY-MM-DD HH:mm:ss'
  if (value) {
    return moment(value).format(formatString)
  } else {
    return '--'
  }
})

//特殊字符替换
/*
* firstNumber 前面保存几个字符
* lastNumber  后面保存几个字符
* middleCharacter 中间用什么字符替换
*
* 手机号码默认传参 4,4,'*'
* 身份证好慢传参格式 6,4,'*'
* */
Vue.filter('specialCharacter', function (value, firstNumber,lastNumber,middleCharacter) {
  if (!firstNumber && firstNumber != 0 ){
    firstNumber = 4
  }
  if (!lastNumber && lastNumber != 0 ){
    lastNumber = 4
  }
  middleCharacter = middleCharacter || '*'
  if (value) {
    let middleLength = value.length - firstNumber - lastNumber
    if (middleLength >= 0){
      let middleContent = ''
      for (let i=0 ; i<middleLength; i++) {
        middleContent += middleCharacter
      }
      return value.slice(0, firstNumber) + middleContent + value.slice(-lastNumber);
    }else {
      return value
    }
  } else {
    return ''
  }
})
