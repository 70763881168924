let webSock = null;
let serverPort = '29527';    //webSocket连接端口
import store from "@/store";

function getWebIP() {
    let curIP = window.location.hostname;
    return curIP;
}

function initWebSocket() { //初始化weosocket
    //ws地址
    if (sessionStorage.getItem('userInfo') != null) {
        let userName = JSON.parse(sessionStorage.getItem('userInfo')).id
        let wsuri = "wss://" + getWebIP() + ":" + serverPort + "/zyd-common/websocket/" + userName;
        webSock = new WebSocket(wsuri);

        webSock.onmessage = function (e) {
            websocketonmessage(e);
        }
        webSock.onclose = function (e) {
            websocketclose(e);
        }
        webSock.onopen = function () {
            websocketOpen();
        }

        //连接发生错误的回调方法
        webSock.onerror = function () {
            console.log("WebSocket连接发生错误");
        }
    }
}

// 实际调用的方法
function sendSock(agentData, callback) {
    websocketonmessage = callback;
    if (webSock.readyState === webSock.OPEN) {
        //若是ws开启状态
        websocketsend(agentData)
    } else if (webSock.readyState === webSock.CONNECTING) {
        // 若是 正在开启状态，则等待1s后重新调用
        setTimeout(function () {
            sendSock(agentData, callback);
        }, 1000);
    } else {
        // 若未开启 ，则等待1s后重新调用
        setTimeout(function () {
            sendSock(agentData, callback);
        }, 1000);
    }
}

//数据接收
function websocketonmessage(e) {
    console.log("数据接收：", e);
    store.state.common.sockPush = e.data;
    console.log("用户id：", store.state.common.sockPush)
}

//数据发送
function websocketsend(agentData) {
    webSock.send(JSON.stringify(agentData));
}

//关闭
function websocketclose(e) {
    console.log("connection closed (" + e.code + ")");
}

function websocketOpen(e) {
    console.log("websocket连接成功");
}

initWebSocket();

export {initWebSocket, sendSock, websocketsend, websocketonmessage}
