// URL地址
export function isURL (s) {
  return /^http[s]?:\/\/.*/.test(s)
}

// 邮箱
export function isEmail (s) {
  return /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(s)
}

// 手机号码
export function isMobile (s) {
  return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(s)
}

// 电话号码
export function isPhone (s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

/*
 * 匹配密码，以字母开头，长度在8-16之间，只能包含字符、数字和下划线。
 * 以字母开头，长度在8-16之间，只能包含字符、数字和下划线。
 */
export function isPwd (value) {
  return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?\":{}|<>]).{8,16}$/.test(value)
}

// 手机验证码
export function isMobileCode (s) {
  return /^\d{6}$/.test(s)
}

// 只能输入[0-9]数字
export function isDigits (value) {
  return /^\d+$/.test(value)
}

// 只能输入中文
export function isChinese (value) {
  return /^[\u4E00-\u9FA5]+$/.test(value)
}

// 价格
export function isPrice (value) {
  return /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)
}

// 不能输入表情
export function isEmoji (value) {
  return /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g.test(value)
}

// 年月日时间加-
export function dateAddFormat (value) {
  return /^(\d{4})(\d{2})(\d{2})$/
}

// 1-999
export function numLimit (value) {
  return /^\+?[1-9]{1}[0-9]{0,2}\d{0,0}$/.test(value)
}

export default {numLimit, dateAddFormat, isEmoji, isURL, isEmail, isMobile, isPhone, isPwd, isMobileCode, isDigits, isChinese, isPrice}
