// 以下多源异构接口api
// 系统模块接口
let sys = {
    // 自定义列表
    getByOriginalId: "sysmodulefield/getById/", //获取自定义字段列表
    getHighQueryFields: "sysmodulefield/getHighQueryFields/",
    getDefaultById: "sysmodulefield/getDefaultById/", //获取默认自定义字段列表
    saveModuleField: "sysmodulefield/save",// 保存自定义列表字段
    removeByOriginalId: "sysmodulefield/removeById/",//恢复默认

    // 场景
    sceneList: "sysqueryscene/list", //查询我的场景
    getConditionById: "sysqueryscene/getConditionById/", //根据id获取条件信息
    sceneSave: "sysqueryscene/save", //保存查询场景
    sceneUpdateById: "sysqueryscene/updateById", //修改场景
    sceneRemoveById: "sysqueryscene/removeById/", //删除我的场景
    sceneShowOrHide: "sysqueryscene/showOrHide",
    getQueryExs: "sysqueryscene/getQueryExs", //显示隐藏我的场景
    getQueryExsY: "sysqueryscene/getQueryExsY", //高级查询条件

    getUserByNameList: "User/getUserByName", // 高级筛选查询用户
}

let original = {
    // 元数据主表
    metaDataDirectoryList: "original/list", // 元数据类目列表

    // 元数据副表
    metaDataList: "originalDescription/list", // 子级列表
    metaDataUpdateById: "originalDescription/updateById", // 子级列表修改
    metaDataRemoveByIds: "originalDescription/removeByIds", //子级元素批量或单个删除
    metaDataSave: "originalDescription/save", // 元数据新增
    originalList: 'original/list',
    originalSave: 'original/save',
    originalEditEnable: 'original/editEnable',
    originalRemoveById: 'original/removeById/',
    originalGetById: 'original/getById',
    originalUpdateById: 'original/updateById',
    originalGetDesc: 'original/getDesc',
    originalBatchAssociation: 'original/batchAssociation',
    originalSelectList: 'original/selectList',
    originalDescriptionBatchExport: 'originalDescription/batchExport',
    originalDescriptionBatchEditing: 'originalDescription/batchEditing',
    getTableNames:'original/getTableNames',

    //业务字段主表
    businessFieldList: "businessField/list", // 主表列表

    // 业务字段附表
    businessFieldDesciptionList: "businessFieldDesciption/list", // 业务副表列表
    businessFieldDesciptionSave: "businessFieldDesciption/save", // 业务副表新增
    businessFieldDesciptionUpdateById: "businessFieldDesciption/updateById", // 业务副表修改
    businessFieldDesciptionRemoveByIds: "businessFieldDesciption/removeByIds", // 业务副表批量删除

    //专题库管理
    thematiclibraryList: 'thematiclibrary/list',
    thematiclibrarySave: 'thematiclibrary/save',
    thematiclibraryRemoveById: 'thematiclibrary/removeById',
    thematiclibraryDownloadFile:'thematiclibrary/downloadFile',
    thematiclibraryUpdateById: 'thematiclibrary/updateById',
    thematiclibraryUpdateEnableState: "thematiclibrary/updateEnableState",
    thematiclibraryresourceList: "thematiclibraryresource/list",
    thematiclibraryresourceList2: "thematiclibraryresource/resourcesList",
    thematiclibraryGetById: 'thematiclibrary/getById/',
    thematiclibraryresourceRemoveById: 'thematiclibraryresource/removeById',
    thematiclibraryCommonList: 'thematiclibrary/commonList',
    homeCollectionSave: 'homeCollection/save',
    thematiclibraryCompareList: 'thematiclibrary/queryCompareList',
    thematiclibraryresourceSave: "thematiclibraryresource/save",
    thematiclibraryresourceExPort: "thematiclibraryresource/exPortList",
    thematicCompareAlike: "thematiclibraryresource/thematicCompareAlike",
    thematicCompareImparity: "thematiclibraryresource/thematicCompareImparity",
    thematiclibraryExcelModel: "thematiclibrary/exportModel",
    thematiclibraryExcelImport: "thematiclibrary/excelImport",
    thematiclibraryRemoveCollection: 'thematiclibrary/removeCollection/',
    thematiclibraryCollection: 'thematiclibrary/collection',
    thematiclibrarypublishSave: 'thematiclibrarypublish/save',
    thematiclibrarypublishUpdateById: 'thematiclibrarypublish/updateById',
    thematiclibrarypublishList: 'thematiclibrarypublish/list',
    thematiclibrarypublishGetById: 'thematiclibrarypublish/getById/',
    thematiclibrarypublishExamine:"thematiclibrarypublish/examine",
    mediathematiclibraryList:'mediathematiclibrary/list',
    mediathematiclibrarySave:'mediathematiclibrary/save',
    mediathematiclibraryRemoveById:'mediathematiclibrary/removeById',
    mediathematiclibraryExportModel:'mediathematiclibrary/exportModel',
    mediathematiclibraryExcelImport:'mediathematiclibrary/excelImport',
    mediathematiclibraryGetById:'mediathematiclibrary/getById/',
    mediathematiclibraryUpdateById:'mediathematiclibrary/updateById',
    'thematiclibraryresourceUpdateById':'thematiclibraryresource/updateById',
    thematiclibraryfieldList:'thematiclibraryfield/list',
    restoreDefault:'thematiclibraryfield/restoreDefault',
    thematiclibraryfieldSave:'thematiclibraryfield/save',

    //图片标注
    dyygmodelGetDyygModel: 'dyygmodel/getDyygModel',
    dyygmodelSave: 'dyygmodeljob/save',
    dyygmodeljobRemoveById:'dyygmodeljob/removeById',
    dyygmodelRemoveById: 'dyygmodel/removeById',
    dyygmodelGetById: 'dyygmodeljob/getById/',
    dyygmodelUpdateById: 'dyygmodeljob/updateById',
    dyygmodelresourceUpdateById: 'dyygmodelresource/updateById',
    dyygmodeljobGetDyygModelJob:'dyygmodeljob/getDyygModelJob',
    recognition:'dyygmodel/recognition',

    //模型训练
    dyygmodelUpdateById1:'dyygmodel/updateById',
    dyygmodelGetById1:'dyygmodel/getById/',
    dyygmodelSave1:'dyygmodel/save',

    //图片比对
    dyygimgmodelList: 'dyygimgmodel/list',
    dyygimgmodelSaveOrUpdate: 'dyygimgmodel/saveOrUpdate',
    dyygimgmodelRemoveById: 'dyygimgmodel/removeById',
    dyygimgmodelEnable: 'dyygimgmodel/enable',
    dyygimgmodelGetById: 'dyygimgmodel/getById/',

    //我的收藏
    myCollectionNum: 'thematiclibrary/myCollectionNum',
    myCollection: 'thematiclibrary/myCollection',
    myCollectionResource: 'thematiclibrary/myCollectionResource/',
    removeById: 'thematiclibrary/removeById',
}

//公用上传表格组件接口
let assembly = {
    downloadExcel: 'zyd-zgww/resourcesExcel/downloadExcel', //下载模板
    downloadExcel1:'zyd-zgww/resourceUpload/downloadExcel',
    goBack: 'zyd-zgww/resourcesExcel/goBack',//重新上传
    list: 'zyd-zgww/resourcesExcel/list',//预览数据
    list1:'zyd-zgww/resourceUpload/list',
    save: 'zyd-zgww/resourcesExcel/save',//执行导入
    save1: 'zyd-zgww/resourceUpload/save',//执行导入
    getTableByResourceName: 'zyd-zgww/originalDescription/getTableByResourceName',//预览表头
    downloadResultExcel:'zyd-zgww/resourceUpload/downloadResultExcel', //下载导入结果
}

//数据匹配接口
let dataMatching = {
    getContentList: 'resourcesDataMatch/getContentList',//获取目录
    dataMatchCachePreheat: 'resourcesDataMatch/dataMatchCachePreheat',//获取当前数据匹配全部列名称
    getFieldByResourceName: 'businessFieldDesciption/getFieldByResourceName',//获取资源名称
    startMatching: 'resourcesDataMatch/startMatching',//开始匹配
    getTableByResourceName: 'originalDescription/getTableByResourceName',//获取页面元数据信息
    resultDataConfirmation: 'resourcesDataMatch/resultDataConfirmation',//获取信息条数
    getList: 'resourcesDataMatch/getList',//获取错误信息
    save: 'resourcesDataMatch/save',//执行匹配
    downloadExcel: 'resourcesDataMatch/downloadExcel',//导出表格
    exit: 'resourcesDataMatch/exit',//删除临时缓存
}

//资源管理接口
let resources = {
    //回收站接口
    recoveryList: "recovery/list",//回收站
    remove: "recovery/remove",//回收站还原

    //资源编目
    catalogingTableHead: "resourceCataloging/catalogingTableHead",//编目表头数据
    getTableByResourcNoInt: "resourceCataloging/getTableByResourcNoInt",//编目表头数据
    querycatalogingList: "resourceCataloging/list",//资源列表
    submitAppraisal: "resourceCataloging/submitAppraisal",//提交鉴定
    resourceHandle: "resourceCataloging/resourceHandle",//智能处理
    generateNumber: 'resourceCataloging/generateNumber',//生成序号
    supplementValue: 'resourceCataloging/supplementValue',//自动补值


    //查询利用
    queryLabelList: 'resourcelabellink/queryLabelList',//资源标签
    getAllGroupName: 'resourceGroup/getAllGroupName', //资源分组
    queryByIdDictTypeValue: 'commonDictValue/queryByIdDictTypeValue',//资源分类
    queryResourceList: 'queryResource/list',//查询利用
    queryLabelRe: 'queryResource/queryLabelRe',//详情页标签
    queryLabelNoRe: 'queryResource/queryLabelNoRe',//标签匹配
    getImageByResourceId: 'queryResource/getImageByResourceId',//获取资源的图片
    getResourceByCollectionId: 'queryResource/getResourceByCollectionId',
    saveLabel: 'queryResource/saveLabel',//新增标签
    getResourcesList: 'queryResource/getResourcesList',//获取表格数据

    resources: 'resources/saveOrUpdate',
    getByNum: 'resourceCollection/getByNum',
    exhibitionUpload: "commonFile/exhibitionUpload",

    getFieldByResourceName: "businessFieldDesciption/getFieldByResourceName",

    delFilePath: "resourceFile/delFilePath",
    cacheFileEntity: "resourceFile/cacheFileEntity",
    listFileCache: "resourceFile/listFileCache",
    listFileSave: "resourceFile/listFileSave",
    setObjMaster: "resourceFile/setObjMaster",
    delFile: "resourceFile/delFile",
    listFile: "resourceFile/listFile",
    setFileMaster: "resourceFile/setFileMaster",
    delEntityFile: "resourceFile/delEntityFile",
    getResourceId: "resources/getById/",
    saveResourceAndFile: "resourceFile/saveResourceAndFile",
    linkCollectionList: "resourceFile/linkCollectionList",
    linkOrDelCollection: "resourceFile/linkOrDelCollection",
    showThreeResource: "resources/showThreeResource",
    submitCatalog: "resources/submitCatalog",

    //标引列表
    labelList: "resourcelabellink/list",
    batchUpdateLabel: "resourcelabellink/batchUpdate",
    delResources: "resources/delResources",
    getfileRecode: "resourceFileRecode/getfileRecode",
    resourceModel: "resources/resourceModel",

    getPercentage: "commonFile/getPercentage",
    removeFileById: "commonFile/removeFileById",

    saveRecode: "resourceFileRecode/saveRecode"

}

// 资源采集接口(ResourceCollection)
let rcPage = {
    // 资源采集
    resourceCollectionList: "resourceCollection/list", // 藏品资源列表
    getCollectionInfo: "resourceCollection/getCollectionInfo/", // 单挑藏品资源信息
    updateCollection: "resourceCollection/updateById", // 修改藏品
    updateCollection2: "resourceCollection/updateById2", // 修改藏品
    getOriginalCollInfo: "originalDescription/getCollectionInfo", // 获取藏品详情字段

    // 采集源设定
    datasourceConfigList: "datasourceConfig/list", // 采集源设定列表
    datasourceConfigUpdateById: "datasourceConfig/updateById", // 采集源设定修改
    datasourceConfigRemoveByIds: "datasourceConfig/removeByIds", //采集源设定删除、或批量删除

    // 采集任务
    taskList: "scheduledTask/list", // 任务列表
    taskSave: "scheduledTask/save", // 新增任务
    taskUpdateById: "scheduledTask/updateById", // 修改任务
    taskSuspendTask: "scheduledTask/suspendTask", // 暂停或恢复任务
    taskRemoveByIds: "scheduledTask/removeByIds", // 删除任务

    // 资源采集
    highQueryResource: "resourcesQuery/highQueryResource",

    queryResourceThematicLibrary: "resourcesQuery/queryResourceThematicLibrary",
    queryResourcesList: "resourcesQuery/queryResourcesList",

    //简单搜索
    ReCjList: "resources/ReCjList",
    getTableByType: "originalDescription/getTableByType",

    recoveryQueryResource: "resourcesQuery/recoveryQueryResource",//回收站 场景
    catalogueQueryResource: "resourcesQuery/catalogueQueryResource",//编目 场景
    exPortList: 'resourceCataloging/exPortList',//下载表格
    downloadFile: 'resourceCataloging/downloadFile',//下载文件

    groupingQueryResource: "resourcesQuery/groupingQueryResource",//资源分组高级查询
    appraisalQueryResource: "resourcesQuery/appraisalQueryResource",//资源鉴定高级查询
    collectionQueryResource: "resourcesQuery/collectionQueryResource",//藏品资源高级查询
    listReleaseQueryCollection: "resourcesQuery/listReleaseQueryCollection",//藏品资源高级查询

    //新增
    getTableByResourceName: 'originalDescription/getTableByResourceName',//资源新增，获取元数据信息
    getByNum: 'resourceCollection/getByNum',//根据藏品编码获取信息
    renderCommonResources: 'resources/uploadResources',//资源采集文件上传地址
    temporaryCommonResources: 'resources/temporaryCommonResources',//新增资源
    removeCacheFiles: 'resources/removeCacheFiles',//上传文件删除
    previousRemoveFolder: 'resources/previousRemoveFolder',//上传文件删除
    uploadDataPreview: 'resources/uploadDataPreview',//上传数预览
    addCommonResources: 'resources/addCommonResources',
    removeTestingPlan: "resourceTestingPlan/removeById/", // 删除方案

    //资源查看
    getResourcesInfo: 'resources/getResourcesInfo',//资源查看
    saveLabel: 'queryResource/saveLabel',//新增标签
    queryLabelRe: 'queryResource/queryLabelRe',//查询标签
    queryLabelNoRe: 'queryResource/queryLabelNoRe',//查询所有标签
    queryLabelRemove: 'resourcelabel/removeById',//移除标签
    list: 'resourceCollection/list',//藏品资源列表

    bindCollection: 'resources/bindCollection',//绑定藏品
    getResourcesInfoRecode: 'resourceFileRecode/getResourcesInfoRecode',
    resourcesUpdateById: 'resources/updateById', // 修改资源
    resourceFileUpdateById: 'resourceFile/updateById', // 修改文件
    resourceFileUpdate: 'resourceFile/update', // 修改文件
    getResources: 'resources/getResources/', // 查询获取下标资源
    resourcesRemoveByIds: 'resources/removeByIds', // 删除资源

    testList: "resourceTestingRecode/list", // 监测列表
    programmeList: "resourceTestingPlan/list", // 方案列表
    programmeSave: "resourceTestingPlan/save", // 方案新增
    testingTypeList: "resourceTestingType/list", // 类型列表
    programmeGetById: "resourceTestingPlan/getById/", // 方案编辑回显
    programmeUpdateById: "resourceTestingPlan/updateById", // 方案修改
    programmeStart: "resourceTestingRecode/startTest/", //开始检测

    pushCollectionList: "resourcepublish/listReleaseCollection", // 藏品发布列表
    pushCollectionSave: "resourcepublish/save", // 藏品发布列表
    pubFieldUpdate: "resourcepublishfield/updateById", // 修改单独字段显示与隐藏
    pubTopicList: "resourcepublishtopic/list", // 栏目列表
    pubTopicSave: "resourcepublishtopic/save", // 栏目新增
    pubTopicUpdateId: "resourcepublishtopic/updateById", // 栏目修改
    pubTopicRemoveById: "resourcepublishtopic/removeById/", // 栏目删除
    getTopicOnCollection: "resourcepublishtopic/getTopicOnCollection/", // 判斷栏目旗下是否有藏品

    pushAdminList: "resourcepublish/listReleaseAdmin", // 藏品管理列表
    pushFieldSet: "originalDescription/fieldSet/", // 发布字段list
    pushFildShow: "originalDescription/controlOnShow", // 发布字段显示隐藏
    pushOperateCollection: "resourcepublish/operateCollection",//控制藏品上下架是否审核或显示隐藏

    getCollResourceInfo: "resourceFile/getReleaseInfo", // 藏品关联藏品
    getCollectionThree: "resources/getCollectionThree", // 藏品关联藏品
    setFileExhibit: "resourceFile/updateSetFileShow", // 设置文件显示与隐藏

    getTestHeadInfo: "resourceTestingRecode/getTestHeadInfo", // 检测头部信息
    getTestList: "resourceTestingRecode/getTestList", // 检测列表信息
    saveTestInfo: "resourceTestingRecode/save", // 检测列表信息

    viewReport: "resourceTestingRecode/viewReport", // 查看报告

    getResourceId: "resources/getResourceId",
    getResourceId1: "resources/getResourceId1",
    updateThreeMaster: "resources/updateThreeMaster",
    resourceFileSave: "resourceFile/save", // 系統新增文件

    saveFileLog: "resourceFileRecode/saveFileLog", // 保存日志
    downloadResources: 'resources/downloadResources', //水印下载
    saveFileLogList: "resourceFileRecode/saveFileLogList", // 批量保存日志
    setBelong: "originalDescription/setBelong", // 设置字段归属
    getQualityInspection: "resourcesQuery/qualityInspection",// 质量检测列表

    publishList: "collectionIndex/publishList",//鉴定后请求刷新缓存
    getResourcesListByLabelOrGroup: "resources/getResourcesListByLabelOrGroup",//根据资源id及类型，得出相关标签及分组其他资源

    adminIndex: "adminIndex/index", // 多源异构首页
    adminQueryPage: "adminIndex/queryPage", // 多源异构检索页
    queryPageFromFile: "adminIndex/queryPageFromFile", // 多源异构检索页文件
    adminQueryPage2: "adminIndex/queryPage2", // 多源异构检索页
    homeColumn: "homeColumn/list",//首页栏目设置
    updateHomeColumn: "homeColumn/updateById",//首页栏目修改
    homeDetailsSave: "homeDetails/save",//详情新增
    homeDetailsList: "homeDetails/list",//详情列表
    updateHomeDetails: "homeDetails/updateById",//详情修改
    updateShowByIds: "homeDetails/updateShowByIds",//批量显示或隐藏

    homeCollectionList: "homeCollection/list",//我的收藏首页
    collectionRemoveByIds: "homeCollection/removeByIds",//移出收藏
    collectionUpdateById: "homeCollection/updateById",//添加收藏

    homeBorrowingList: "homeBorrowing/list",//我的借阅，借阅审批，审批历史
    homeBorrowingUpdateById: "homeBorrowing/updateById",//修改
    giveBack: "homeBorrowing/giveBack",//归还
    homeBorrowingSave: "homeBorrowing/save",//新增
    borrowingDownloadFile: "resourceFile/borrowingDownloadFile",//我的借阅下载文件

    removerByLabelIdAndResourceId: "resourcelabellink/removerByLabelIdAndResourceId",//删除文物里面单个标签，不影响其他文物标签
}

//资源分组接口
let grouping = {
    resourcesList: "resources/list",  // 所有资源列表
    // 左侧目录
    resourcesGroupList: "resourceGroup/list", // 左侧目录列表
    resourcesGroupSave: "resourceGroup/save", // 新建编组
    resourcesGroupUpdateById: "resourceGroup/updateById", // 左侧列表修改
    resourcesGroupRemoveByIds: "resourceGroup/removeByIds", // 左侧列表删除组
    // 右侧目录
    resourceGroupLinkList: "resourceGroupLink/list", // 分组列表
    resourceGroupLinkRemoveByIds: "resourceGroupLink/removeByIds/", // 分组撤销分组
    getAllGroupName: 'resourceGroup/getAllGroupName',//全分组名称
    save: 'resourceGroup/save',//资源编组
    removeByIds: 'resourceGroupLink/removeByIds/',//取消编组
}

//资源鉴定接口
let identification = {
    list: 'resourceIdentification/list',//数据列表
    getTableByResourceName: 'originalDescription/getTableByResourceName',//脱敏选择项
    appraisalOrGoBack: 'resourceIdentification/appraisalOrGoBack',//鉴定、回退、受控、开放
    save: 'encryptionField/save',//脱敏保存
    cancelEncryption: "encryptionField/cancelEncryption", // 取消脱敏
    echoField: "encryptionField/echoField", // 回显
}
// 公共上传文件
let uploadCommon = {
    uploadFile: 'commonFile/upload', // 公共上传文件
    resourceUploadFile: 'commonFile/resourcesUpload', // 公共上传文件 除三维资源
    richTextUpload: 'commonFile/richTextUpload' // 富文本上传
}
// 以上多源异构接口api


let auth = {
    verifySms: 'zyd-common/tools/sendSmsMessage',
    basesysrole: 'zyd-user/basesysrole/list',
    loginOut: 'zyd-user/login/loginOut',
    queryRoleUserList: 'zyd-user/basesysrole/queryRoleUserList',
    basesysroleSave: 'zyd-user/basesysrole/save',
    basesysroleBatchRemove: 'zyd-user/basesysrole/batchRemove',
    getMenus: 'zyd-user/sysuser/getMenus',  // 用户权限查询接口
    getById: 'zyd-user/sysrolemenu/getById',  // 查询角色权限
    sysroleList: 'zyd-user/sysrole/list',  // 角色列表
    findAllMenu: 'zyd-user/sysnewmenu/findAllMenu',  // 角色列表
    sysnewmenuList: 'zyd-user/sysnewmenu/list',  // 查询菜单列表
    sysnewmenuRemoveById: 'zyd-user/sysnewmenu/removeById',  // 删除菜单
    sysnewmenuUpdateById: 'zyd-user/sysnewmenu/updateById',  // 修改菜单信息
    sysnewmenuSave: 'zyd-user/sysnewmenu/save',  // 新增菜单信息
    sysrolemenuSave: 'zyd-user/sysrolemenu/save',  // 角色授权保存
    removeById: 'zyd-user/sysuserrole/removeById',  // 移除角色用户
    sysroleGetById: 'zyd-user/sysrole/getById',  // 查询角色信息
    sysroleUpdateById: 'zyd-user/sysrole/updateById',  // 修改角色信息
    sysroleRemoveById: 'zyd-user/sysrole/removeById',  // 删除角色
    sysroleSave: 'zyd-user/sysrole/save',  // 新增角色
    sysuserQueryAllList: 'zyd-user/sysuser/queryUserList',  // 查询所有用户
    sysuserQueryRoleUserList: 'zyd-user/sysuserrole/queryRoleUserList',  // 查询非角色用户
    getByIdAllUser: 'zyd-user/sysuserrole/getByIdAllUser',  // 根据角色查询用户列表
    sysuserroleSave: 'zyd-user/sysuserrole/save',  // 批量关联角色用户
    login: "zyd-user/login/login", // 登录
    loginout: "zyd-user/login/loginout", // 登出日志记录
    findList: "zyd-common/searchDoc",
    filesave: "zyd-catalogue/sysfile/save",
    onlinePreview: "zyd-catalogue/sysfile/upload",
    listFiles: "zyd-catalogue/sysfile/listFiles",
    deleteFile: "zyd-catalogue/sysfile/deleteFile",//删除文件
    sysuserUpdateById: "zyd-user/sysuser/updateById", // 修改用户信息
    sysuserRemove: "zyd-user/sysuser/remove", // 删除用户信息
    sysuserSave: "zyd-user/sysuser/save", // 保存用户
    GetUserById: "zyd-user/sysuser/getById",
    removeLoginRestrictions:'zyd-user/sysuser/removeLoginRestrictions/',
    updateSysUserPassword:'zyd-user/sysuser/updateSysUserPassword',//修改密码
    //字典管理
    dictTypeList: "zyd-common/dictType/queryAllByLimitDictType",
    dictQueryById: "zyd-common/dictType/queryByIdDictType",
    dictSave: "zyd-common/dictType/insertDictType",
    updateDictType: "zyd-common/dictType/updateDictType",
    dictDelete: "zyd-common/dictType/deleteDictType",
    getDictMap2: "zyd-common/dictType/getDictMap",


    dictGetDictValue: "zyd-common/dictValue/queryAllDictValue",
    dictQueryDictValue: "zyd-common/dictValue/queryByIdDictValue",
    dictSaveDictValue: "zyd-common/dictValue/insertDictValue",
    updateDictValue: "zyd-common/dictValue/updateDictValue",
    dictDeleteDictValue: "zyd-common/dictValue/deleteByIdDictValue",
    getDictMap: "zyd-common/dictValue/queryByIdDictTypeValue",
    uploadFile: "zyd-catalogue/sysfile/save",


    customColList: "zyd-common/customcol/customColList",
    save: "zyd-common/customcol/save",
    getversion: "zyd-common/getversion",

    getSysUserByName: 'zyd-user/sysuser/getSysUserByName',

    // 雷锋
    systemporaryauthority: 'zyd-user/systemporaryauthority/list',
    getSysTemporaryAuthorityRole: 'zyd-user/systemporaryauthority/getSysTemporaryAuthorityRole',
    getSysDepartment: 'zyd-user/sysdepartment/getSysDepartment',
    departmentRemoveById: 'zyd-user/sysdepartment/removeById',
    departmentSave: 'zyd-user/sysdepartment/save',
    departmentUpdateById: 'zyd-user/sysdepartment/updateById',
    sysdepartmentGetById: 'zyd-user/sysdepartment/getById',
    getSysLibrary: 'zyd-user/syslibrary/getSysLibrary',
    syslibrarySave: 'zyd-user/syslibrary/save',
    syslibraryRemoveById: 'zyd-user/syslibrary/removeById',
    syslibraryUpdateById: 'zyd-user/syslibrary/updateById',
    syslibraryGetById: 'zyd-user/syslibrary/getById',
    systemporaryauthorityList: 'zyd-user/systemporaryauthority/list',
    systemporaryauthoritySave: 'zyd-user/systemporaryauthority/save',
    systemporaryauthorityGetById: 'zyd-user/systemporaryauthority/getById',
    systemporaryauthorityUpdateById: 'zyd-user/systemporaryauthority/updateById',
    systemporaryauthorityRmoveById: 'zyd-user/systemporaryauthority/removeById',
    sysrolemenutempGetById: 'zyd-user/sysrolemenutemp/getById',
    sysrolemenutempSave: 'zyd-user/sysrolemenutemp/save',
    getSysDepartmentLibrary: 'zyd-user/sysdepartment/getSysDepartmentLibrary',
    exportUserList: 'zyd-user/sysuser/exportUserList',
    // 我的消息
    selectMessageList: 'zyd-common/commonMessage/selectMessageList',
    messageRemoveById: 'zyd-common/commonMessage/removeById',
    messageGetById: 'zyd-common/commonMessage/getById',
    getCount: 'zyd-common/commonMessage/getCount',
    getByMessageType: 'zyd-common/commonMessageSetting/getByMessageType',
    updateByType: 'zyd-common/commonMessageSetting/updateByType',
    // 工作台
    commonBacklog: 'zyd-common/commonBacklog/list',
    selectByDate: 'zyd-common/commonBacklog/selectByDate',
    commonBacklogRemoveById: 'zyd-common/commonBacklog/removeById',
    commonBacklogNoteSave: 'zyd-common/commonBacklogNote/save',
    commonBacklogNoteRemoveById: 'zyd-common/commonBacklogNote/removeById',
    commonBacklogNoteUpdateById: 'zyd-common/commonBacklogNote/updateById',
    commonBacklogNoteList: 'zyd-common/commonBacklogNote/list',
    findByMenuResult: 'zyd-user/sysmenushortcutkeys/findByMenuResult',
    getSysMenuShortcutKeysByCreateBy: 'zyd-user/sysmenushortcutkeys/getSysMenuShortcutKeysByCreateBy',
    sysmenushortcutkeysSave: 'zyd-user/sysmenushortcutkeys/save',
    uploadRecode:'zyd-collection/index/uploadRecode',
    recodesettingList:'zyd-zgww/recodesetting/list',
    recodesettingRemoveById:'zyd-zgww/recodesetting/removeById/',
    recodesettingListAddUser:'zyd-zgww/recodesetting/listAddUser',
    recodesettingSave:'zyd-zgww/recodesetting/save',
    //参数管理
    configList: 'zyd-user/system/config/list',
    systemConfig: 'zyd-user/system/config',
    configClearCache: 'zyd-user/system/config/clearCache',
    getIsSysByUserId: 'zyd-user/sysrole/getIsSysByUserId',
    exportSysDepartment: 'zyd-user/sysdepartment/exportSysDepartment',
    // 上传设置
    businesssetting: 'zyd-common/businesssetting/list',
    businesssettingUpdateById: 'zyd-common/businesssetting/updateById',
    // 系统设置
    getUserPasswordChangeReminder: 'zyd-user/system/config/getUserPasswordChangeReminder',
    //导入设置
    exportSettingInfo:'zyd-collection/collectiondatacheckin/exportSettingInfo',
    exportSettingUpdate:'zyd-collection/collectiondatacheckin/exportSettingUpdate',
}


// 雷锋档案
let record = {
    getCreateArchivesBasic: 'zyd-archives/archivesbasic/getCreateArchivesBasic',
    selectArchivesBasic: 'zyd-archives/archivesbasic/selectArchivesBasic',
    archivesRemoveById: 'zyd-archives/archivesbasic/removeById',
    archivesSave: 'zyd-archives/archivesbasic/save',
    selectArchivesBasicData: 'zyd-archives/archivesbasicdata/selectArchivesBasicData',
    getAllArchivesBasicType: 'zyd-archives/archivesbasictype/getAllArchivesBasicType',
    basicdataSave: 'zyd-archives/archivesbasicdata/save',
    selectRequiredArchivesBasic: 'zyd-archives/archivesbasic/selectRequiredArchivesBasic',
    selectAllArchivesBasic: 'zyd-archives/archivesbasic/selectAllArchivesBasic',
    basicdataRemoveById: 'zyd-archives/archivesbasicdata/removeById',
    basicdataUpdateById: 'zyd-archives/archivesbasicdata/updateById',
    basicdataGetById: 'zyd-archives/archivesbasicdata/getById',
    tepCheckRemoveById: 'zyd-archives/archivesbasicdata/checkRemoveById',
    selectArchivesBasicMultiselect: 'zyd-archives/archivesbasicmultiselect/selectArchivesBasicMultiselect',
    multiselectRemoveById: 'zyd-archives/archivesbasicmultiselect/removeById',
    multiselectSave: 'zyd-archives/archivesbasicmultiselect/save',
    multiselectUpdateById: 'zyd-archives/archivesbasicmultiselect/updateById',
    queryElasticsearchTemplatePage: 'zyd-archives/archivesdata/queryElasticsearchTemplatePage',
    selectAllTextArchivesBasic: 'zyd-archives/archivesbasic/selectAllTextArchivesBasic',
    checkRemoveById: 'zyd-archives/archivesbasic/checkRemoveById',
    collectSave: 'zyd-archives/archivesdata/save',
    getById: 'zyd-archives/archivesdata/getById',
    removeById: 'zyd-archives/archivesdata/removeById',
    export: 'zyd-archives/archivesexport/export',
    exportModel: 'zyd-archives/archivesexport/exportModelText',
    excelImport: 'zyd-archives/archivesexport/excelImport',
    getArchivesDataCountGroup: 'zyd-archives/archivesdata/getArchivesDataCountGroup',
    downloadArchivesFileZip: 'zyd-archives/archivesfile/downloadArchivesFileZip',
    downloadArchivesFileZips: 'zyd-archives/archivesfile/downloadArchivesFileZips',
    checkDownloadArchivesFileZips: 'zyd-archives/archivesfile/checkDownloadArchivesFileZips',
    downloadArchivesFile: 'zyd-archives/archivesfile/downloadArchivesFile',
    queryDatabaseSearchPage: 'zyd-archives/archivesdata/queryDatabaseSearchPage',
    getArchivesBasicByRequired: 'zyd-archives/archivesbasicdata/getArchivesBasicByRequired',
    searchArchivesDoc: 'zyd-archives/archivesdoc/searchArchivesDoc',
    utilizePrint: 'zyd-archives/archivesfile/utilizePrint',
    utilizeQuery: 'zyd-archives/archivesfile/utilizeQuery',
    copyArchivesData: 'zyd-archives/archivesdata/copyArchivesData',
    checkReference: 'zyd-archives/archivesdata/checkReference',
    getArchivesDataUtilizeCountGroup: 'zyd-archives/archivesdata/getArchivesDataUtilizeCountGroup',
    getArchivesDataWarehousingCountGroup: 'zyd-archives/archivesdata/getArchivesDataWarehousingCountGroup',
    downLoadArchivesDataCountGroup: 'zyd-archives/archivesdata/downLoadArchivesDataCountGroup',
    downLoadArchivesDataUtilizeCountGroup: 'zyd-archives/archivesdata/downLoadArchivesDataUtilizeCountGroup',
    downLoadArchivesDataWarehousingCountGroup: 'zyd-archives/archivesdata/downLoadArchivesDataWarehousingCountGroup',
    getArchivesDocHistoryByCreateBy: 'zyd-archives/archivesdochistory/getArchivesDocHistoryByCreateBy',
    cleanArchivesDocHistory: 'zyd-archives/archivesdochistory/cleanArchivesDocHistory',
    removeHistory: 'zyd-archives/archivesdochistory/removeById',
    getArchivesUtilizeByCreateBy: 'zyd-archives/archivesutilize/getArchivesUtilizeByCreateBy',
    archivesutilizeRemoveById: 'zyd-archives/archivesutilize/removeById',
    getArchivesUtilizeByCountGroup: 'zyd-archives/archivesutilize/getArchivesUtilizeByCountGroup',
    // 借阅
    borrowResponsible: 'zyd-archives/archivesborrow/borrowResponsible',
    auditBorrow: 'zyd-archives/archivesborrow/auditBorrow',
    responsibleBorrow: 'zyd-archives/archivesborrow/responsibleBorrow',
    findUserAuditState: 'zyd-archives/archivesborrow/findUserAuditState',
    borrowList: 'zyd-archives/archivesborrow/list',
    addElectronicBorrow: 'zyd-archives/archivesborrow/addElectronicBorrow',
    updateArchivesBorrow: 'zyd-archives/archivesborrow/updateArchivesBorrow',
    viewDetails: 'zyd-archives/archivesborrow/viewDetails',
    updateAudit: 'zyd-archives/archivesborrow/updateAudit',
    electronicSubmit: 'zyd-archives/archivesborrow/electronicSubmit',
    borrowRemoveById: 'zyd-archives/archivesborrow/removeById',
    findFileSize: 'zyd-archives/archivesborrow/findFileSize',
    electronicReturn: 'zyd-archives/archivesborrow/electronicReturn',
    auditsList: 'zyd-archives/archivesborrow/auditsList',
    lastOrNext: 'zyd-archives/archivesborrow/lastOrNext',
    getByBorrowId: 'zyd-archives/archivesborrowinfo/getByBorrowId',
    readList: 'zyd-archives/archivesborrow/readList',
    addEntity: 'zyd-archives/archivesborrow/addEntity',
    readDetails: 'zyd-archives/archivesborrow/readDetails',
    readReturn: 'zyd-archives/archivesborrow/readReturn',
    getAuditByType: 'zyd-archives/archivesborrowinfo/getAuditByType',
    getAuditByBorrowId: 'zyd-archives/archivesborrowinfo/getAuditByBorrowId',
    getAuditByList: 'zyd-archives/archivesborrowinfo/getAuditByList',
    updateEntityBorrow: 'zyd-archives/archivesborrow/updateEntityBorrow',
    entityBorrowList: 'zyd-archives/archivesborrow/entityBorrowList',
    entityDetail: 'zyd-archives/archivesborrow/entityDetail',
    entityAudit: 'zyd-archives/archivesborrow/entityAudit',
    entitySubmit: 'zyd-archives/archivesborrow/entitySubmit',
    entityAuditsList: 'zyd-archives/archivesborrow/entityAuditsList',
    entityLastOrNext: 'zyd-archives/archivesborrow/entityLastOrNext',
    lendList: 'zyd-archives/archivesborrow/lendList',
    entityLends: 'zyd-archives/archivesborrow/entityLends',
    entityReturns: 'zyd-archives/archivesborrow/entityReturns',
    logList: 'zyd-archives/archivesoperationlog/list',
    getCreateArchivesOperationLog: 'zyd-archives/archivesoperationlog/getCreateArchivesOperationLog',
    exportArchivesOperationLog: 'zyd-archives/archivesoperationlog/exportArchivesOperationLog',
    exportAllArchivesOperationLog: 'zyd-archives/archivesoperationlog/exportAllArchivesOperationLog',
    exportResourceFileDownloadLog:'zyd-zgww/resourceFileDownloadLog/exportResourceFileDownloadLog',
    exportResourceFileDownloadLogAll:'zyd-zgww/resourceFileDownloadLog/exportResourceFileDownloadLogAll',
    exportEsLog:'zyd-common/exportEsLog',
    exportEsLogAll:'zyd-common/exportEsLogAll',
    getByRoleId: 'zyd-archives/archivesrolepermissions/getByRoleId',
    rolePermissionsSave: 'zyd-archives/archivesrolepermissions/save',
    findReadBorrowName: 'zyd-archives/archivesborrow/findReadBorrowName',
    archivesTransferList: 'zyd-archives/archivestransfer/archivesTransferList',
    auditTransfer: 'zyd-archives/archivestransfer/auditTransfer',
    archivestransferAdd: 'zyd-archives/archivestransfer/add',
    removeArchivesTransferByIds: 'zyd-archives/archivestransfer/removeArchivesTransferByIds',
    updateArchivesTransferById: 'zyd-archives/archivestransfer/updateArchivesTransferById',
    getArchivesTransferById: 'zyd-archives/archivestransfer/getArchivesTransferById',
    archivespersonalfavoritesSave: 'zyd-archives/archivespersonalfavorites/save',
    getArchivesPersonalFavoritesByCreateBy: 'zyd-archives/archivespersonalfavorites/getArchivesPersonalFavoritesByCreateBy',
    collectionGetArchivesPersonalFavoritesByCreateBy: 'zyd-collection/archivespersonalfavorites/getArchivesPersonalFavoritesByCreateBy',
    archivespersonalfavoritesRemoveById: 'zyd-archives/archivespersonalfavorites/removeById',
    collectionArchivespersonalfavoritesRemoveById: 'zyd-collection/archivespersonalfavorites/removeById',
    getBySysTemporaryAuthorityId: 'zyd-archives/archivesrolepermissionstemp/getBySysTemporaryAuthorityId',
    archivesrolepermissionstempSave: 'zyd-archives/archivesrolepermissionstemp/save',
    findEntityBorrowName: 'zyd-archives/archivesborrow/findEntityBorrowName',
    findEntityLendName: 'zyd-archives/archivesborrow/findEntityLendName',
    lendListLastOrNext: 'zyd-archives/archivesborrow/lendListLastOrNext',
    getArchivesUtilizeCount: 'zyd-archives/archivespersonalfavorites/getArchivesUtilizeCount',
    collGectiongetArchivesUtilizeCount: 'zyd-collection/archivespersonalfavorites/getArchivesUtilizeCount',
    setAuditList: 'zyd-archives/archivesborrowrevd/auditList',
    setAuditEditInfo: 'zyd-archives/archivesborrowrevd/auditEditInfo',
    auditEditInfoSave: 'zyd-archives/archivesborrowrevd/auditEditInfoSave',
    listRevdEnable: 'zyd-archives/archivesborrowrevd/listRevdEnable',
    findArchivesTransferDepartment: 'zyd-archives/archivestransfer/findArchivesTransferDepartment',
    getByMaxNumber: 'zyd-archives/borrownumber/getByMaxNumber',
    updateMaxNumber: 'zyd-archives/borrownumber/updateMaxNumber',
    getByBorrowType: 'zyd-archives/borrownumber/getByBorrowType',
    zipExtractor: 'zyd-archives/archivesfile/zipExtractor',
    executeImport: 'zyd-archives/archivesfile/executeImport',
    archivesexecuteimport: 'zyd-archives/archivesexecuteimport/list',
    getArchivesExecuteimport: 'zyd-archives/archivesexecuteimport/getArchivesExecuteimport',
    // 图书
    archivesbookSave: 'zyd-archives/archivesbook/save',
    archivesbookGetById: 'zyd-archives/archivesbook/getById',
    archivesbookList: 'zyd-archives/archivesbook/list',
    archivesbookUpdateById: 'zyd-archives/archivesbook/updateById',
    archivesbookDeleteById: 'zyd-archives/archivesbook/deleteById',
    publishingHouseList: 'zyd-archives/archivesbook/publishingHouseList',
    bookborrowList: 'zyd-archives/bookborrow/list',
    findBorrowUserAndCreateBy: 'zyd-archives/bookborrow/findBorrowUserAndCreateBy',
    bookborrowSave: 'zyd-archives/bookborrow/save',
    bookborrowUpdateById: 'zyd-archives/bookborrow/updateById',
    bookborrowGetById: 'zyd-archives/bookborrow/getById',
    returnBorrow: 'zyd-archives/bookborrow/returnBorrow',
    // 借阅记录
    findBorrowRecordByBorrowCodeNumber: 'zyd-archives/archivesborrow/findBorrowRecordByBorrowCodeNumber',
    findBorrowRecordByArchivesData: 'zyd-archives/archivesborrow/findBorrowRecordByArchivesData',
    findUserNameByBorrowCodeNumber: 'zyd-archives/archivesborrow/findUserNameByBorrowCodeNumber',
    borrowCodeNumberExport: 'zyd-archives/archivesborrow/borrowCodeNumberExport',
    borrowArchivesDataExport: 'zyd-archives/archivesborrow/borrowArchivesDataExport',
    // 关联
    archivesbasicdatacasefile: 'zyd-archives/archivesbasicdatacasefile/getArchivesBasicDataCaseFileByCaseFileArchivesBasicDataId',
    archivesbasicdata: 'zyd-archives/archivesbasicdata/getArchivesDataByArchivesBasicDataType',
    archivesbasicdatacasefileSave: 'zyd-archives/archivesbasicdatacasefile/save',
    getArchivesBasicDataCaseFileByArchivesDataId: 'zyd-archives/archivesbasicdatacasefile/getArchivesBasicDataCaseFileByArchivesDataId',
    withinTheVolumeByArchivesDataId: 'zyd-archives/archivesbasicdatacasefile/getArchivesBasicDataWithinTheVolumeByArchivesDataId',
    getSysSelectArchivesBasic: 'zyd-archives/archivesbasic/getSysSelectArchivesBasic',
    // 借阅总数
    archivesBorrowTotalList: 'zyd-archives/archivesborrow/archivesBorrowTotalList',
    archivesBorrowTotalReadList: 'zyd-archives/archivesborrow/archivesBorrowTotalReadList',
    archivesBorrowTotalEntityBorrowList: 'zyd-archives/archivesborrow/archivesBorrowTotalEntityBorrowList',
    borrowAmountTotal: 'zyd-archives/bookborrow/borrowAmountTotal',
    findArchiveNumberByBorrowCodeNumber: 'zyd-archives/archivesborrow/findArchiveNumberByBorrowCodeNumber',
    downloadArchivesFilesZip: 'zyd-archives/archivesfile/downloadArchivesFilesZip',
    retrieveMetadataFromDatabaseRetrieval: 'zyd-archives/archivesbasic/retrieveMetadataFromDatabaseRetrieval',
}

//典藏系统
let collection = {
    archivesbasicSelectAllArchivesBasic:'zyd-collection/archivesbasic/selectAllArchivesBasic',
    archivesbasicGetSysSelectArchivesBasic:'zyd-collection/archivesbasic/getSysSelectArchivesBasic',
    getSysSelectArchivesBasic: 'zyd-collection/archivesbasic/getSysSelectArchivesBasic',
    loggffExport: 'zyd-collection/archivesdataoperation/loggffExport',
    queryElasticsearchTemplatePage: 'zyd-collection/archivesdata/queryElasticsearchTemplatePage',
    selectArchivesBasicData: 'zyd-collection/archivesbasicdata/selectArchivesBasicData',
    basicdataGetById: 'zyd-collection/archivesbasicdata/getById',
    getArchivesBasicByRequired: 'zyd-collection/archivesbasicdata/getArchivesBasicByRequired',
    retrieveMetadataFromDatabaseRetrieval:'zyd-collection/archivesbasic/retrieveMetadataFromDatabaseRetrieval',
    listLevelSelect: 'zyd-collection/collectiondatacheckin/listLevelSelect',
    listSelect: 'zyd-collection/collectiondatacheckin/listSelect',
    auxiliaryAccountLedger: 'zyd-collection/collectiondata/auxiliaryAccountLedger',
    selectCollectionDataCount: 'zyd-collection/collectiondata/selectCollectionDataCount',
    listCollectionRevdPage: 'zyd-collection/collectiondatacheckin/listCollectionRevdPage',
    selectCollectionDataStateCount: 'zyd-collection/collectiondatacheckin/selectCollectionDataStateCount',
    draftSave: 'zyd-collection/collectiondatacheckin/draftSave',
    submit: 'zyd-collection/collectiondatacheckin/submit',
    updateCollectionDataUnderReview: 'zyd-collection/collectiondatacheckin/updateCollectionDataUnderReview',
    selectArchivesBasic: 'zyd-collection/archivesbasic/selectArchivesBasic',
    getAllArchivesBasicType: 'zyd-collection/archivesbasictype/getAllArchivesBasicType',
    checkRemoveById: 'zyd-collection/archivesbasic/checkRemoveById',
    archivesRemoveById: 'zyd-collection/archivesbasic/removeById',
    archivesSave: 'zyd-collection/archivesbasic/save',
    selectAllTextArchivesBasic: 'zyd-collection/archivesbasic/selectAllTextArchivesBasic',
    multiselectSave: 'zyd-collection/archivesbasicmultiselect/save',
    multiselectUpdateById: 'zyd-collection/archivesbasicmultiselect/updateById',
    selectArchivesBasicMultiselect: 'zyd-collection/archivesbasicmultiselect/selectArchivesBasicMultiselect',
    multiselectRemoveById: 'zyd-collection/archivesbasicmultiselect/removeById',
    basicdataRemoveById: 'zyd-collection/archivesbasicdata/removeById',
    selectRequiredArchivesBasic: 'zyd-collection/archivesbasic/selectRequiredArchivesBasic',
    selectAllArchivesBasic: 'zyd-collection/archivesbasic/selectAllArchivesBasic',
    basicdataSave: 'zyd-collection/archivesbasicdata/save',
    basicdataUpdateById: 'zyd-collection/archivesbasicdata/updateById',
    bacthSubmit: 'zyd-collection/collectiondatacheckin/bacthSubmit',
    exportModel: 'zyd-collection/archivesdataoperation/exportModel',
    getById: 'zyd-collection/archivesdata/getById',
    removeById: 'zyd-collection/archivesdata/removeById/',
    export: 'zyd-collection/archivesdataoperation/export',
    listFileByDataId: 'zyd-collection/collectionfile/listFileByDataId',
    collectionprocessinfoList: 'zyd-collection/collectionprocessinfo/list',
    getAuditByType:'zyd-collection/collectionprocessinfo/getAuditByType',
    revd: 'zyd-collection/collectiondatacheckin/revd',
    excelImport: 'zyd-collection/archivesdataoperation/excelImport',
    tepCheckRemoveById: 'zyd-collection/archivesbasicdata/checkRemoveById',
    listCollectionRevdInfoNextOrPre: 'zyd-collection/collectiondatacheckin/listCollectionRevdInfoNextOrPre',
    listCollectionInfoNextOrPre: 'zyd-collection/archivesdata/listCollectionInfoNextOrPre',
    collectiondonateList: 'zyd-collection/collectiondonate/list',
    saveOrUpdateDraft: 'zyd-collection/collectiondonate/saveOrUpdateDraft',
    saveOrUpdateSubmit: 'zyd-collection/collectiondonate/saveOrUpdateSubmit',
    batchExport: 'zyd-collection/collectiondonate/batchExport',
    batchSubmit: 'zyd-collection/collectiondonate/batchSubmit',
    listInfo: 'zyd-collection/collectiondonate/listInfo',
    collectiondonateRemoveById: 'zyd-collection/collectiondonate/removeById/',
    listCollection: 'zyd-collection/collectiondonate/ListCollection',
    collectiondataGetById: 'zyd-collection/collectiondata/getById/',
    derreviewCollectiondataGetById: 'zyd-collection/collectiondataunderreview/getById/',
    listFileUnderReviewByDataId: 'zyd-collection/collectionfileunderreview/listFileUnderReviewByDataId',
    collectiondonateRevd: 'zyd-collection/collectiondonate/revd',
    card: 'zyd-collection/card/card',
    listRev: 'zyd-collection/collectiondonate/listRev',
    batchCard: 'zyd-collection/card/batchCard',
    collectiondisinfectList: 'zyd-collection/collectiondisinfect/list',
    collectiondisinfectExport: 'zyd-collection/collectiondisinfect/export',
    collectiondisinfectSave: 'zyd-collection/collectiondisinfect/save',
    collectiondisinfectGetByid: 'zyd-collection/collectiondisinfect/getById/',
    collectiondisinfectUpDate: 'zyd-collection/collectiondisinfect/updateById',
    collectiondisinfectRemove: 'zyd-collection/collectiondisinfect/removeById/',
    collectionQueryElasticsearchTemplatePage: 'zyd-collection/collectionlogoff/queryElasticsearchTemplatePage',
    logoffBacthSubmit: 'zyd-collection/collectionlogoff/bacthSubmit',
    auditRecords: 'zyd-collection/collectionlogoff/auditRecords',
    collectionlogoffRemoveById: 'zyd-collection/collectionlogoff/removeById/',
    logoffList: 'zyd-collection/collectionlogoff/logoffList',
    batchExamine: 'zyd-collection/collectionlogoff/batchExamine',
    collectionlogoffDetail: 'zyd-collection/collectionlogoff/getById/',
    collectionlogoffSave: 'zyd-collection/collectionlogoff/save',
    collectionlogoffUpdate: 'zyd-collection/collectionlogoff/updateById',
    logoffListCollectionRevdInfoNextOrPre: 'zyd-collection/collectionlogoff/listCollectionRevdInfoNextOrPre',
    collectionlogoffListInfoNextOrPre: 'zyd-collection/collectionlogoff/listInfoNextOrPre',
    listRevNextOrPre: 'zyd-collection/collectiondonate/listRevNextOrPre',
    listNextOrPre: 'zyd-collection/collectiondonate/listNextOrPre',
    excelImportNoSave: 'zyd-collection/archivesdataoperation/excelImportNoSave',
    listAll: 'zyd-collection/collectionImmovableCulturalRelicType/listAll',
    collectionImmovableCulturalRelicType: 'zyd-collection/collectionImmovableCulturalRelicType/getById/',
    collectionImmovableCulturalRelic: 'zyd-collection/collectionImmovableCulturalRelic/draftSave',
    collectionImmovableCulturalRelicSubmit: 'zyd-collection/collectionImmovableCulturalRelic/submit',
    collectionImmovableCulturalRelicTypeList: 'zyd-collection/collectionImmovableCulturalRelicType/list',
    collectionImmovableCulturalRelicList: 'zyd-collection/collectionImmovableCulturalRelic/list',
    collectionImmovableCulturalRelicListExamine: 'zyd-collection/collectionImmovableCulturalRelic/listExamine',
    templateDownload: 'zyd-collection/archivesdataoperation/templateDownload',
    collectionImmovableCulturalRelicGetById: 'zyd-collection/collectionImmovableCulturalRelic/getById/',
    relicBacthSubmit: 'zyd-collection/collectionImmovableCulturalRelic/bacthSubmit',
    relicRevd: 'zyd-collection/collectionImmovableCulturalRelic/revd',
    relicRemove: 'zyd-collection/collectionImmovableCulturalRelic/remove/',
    listCollectionRevdInfoNextOrPreRelic: 'zyd-collection/collectionImmovableCulturalRelic/listCollectionRevdInfoNextOrPre',
    importExcel: 'zyd-collection/archivesdataoperation/importExcel',
    collectionImmovableCulturalRelicExport: 'zyd-collection/collectionImmovableCulturalRelic/collectionImmovableCulturalRelicExport',
    exhibitioninfoList: 'zyd-collection/exhibitioninfo/list',
    exhibitioninfoexamineList: 'zyd-collection/exhibitioninfo/examineList',
    exhibitioninfoSave: 'zyd-collection/exhibitioninfo/save',
    exhibitioninfoSubmit: 'zyd-collection/exhibitioninfo/submit/',
    exhibitioninfoGetById: 'zyd-collection/exhibitioninfo/getById/',
    exhibitioninfoAuditRecords: 'zyd-collection/exhibitioninfo/auditRecords',
    exhibitioninfoRemoveById: 'zyd-collection/exhibitioninfo/removeById/',
    exhibitioninfoExport: 'zyd-collection/exhibitioninfo/export',
    exhibitioninfoExamine: 'zyd-collection/exhibitioninfo/batchExamine',
    appraisalexpertList: 'zyd-collection/appraisalexpert/list',
    appraisalexpertExport: 'zyd-collection/appraisalexpert/export',
    appraisalexpertSubmit: 'zyd-collection/appraisalexpert/submit/',
    appraisalexpertAuditRecords: 'zyd-collection/appraisalexpert/auditRecords',
    appraisalexpertRemoveById: 'zyd-collection/appraisalexpert/removeById/',
    exhibitioninfoById: 'zyd-collection/exhibitioninfo/updateById',
    appraisalexpertSave: 'zyd-collection/appraisalexpert/save',
    appraisalexpertById: 'zyd-collection/appraisalexpert/updateById',
    appraisalexpertGetById: 'zyd-collection/appraisalexpert/getById/',
    appraisalexpertExamine: 'zyd-collection/appraisalexpert/batchExamine',
    collectionappraisalList: 'zyd-collection/collectionappraisal/list',
    collectionappraisalExport: 'zyd-collection/collectionappraisal/export',
    collectionappraisalSubmit: 'zyd-collection/collectionappraisal/submit/',
    collectionappraisalAuditRecords: 'zyd-collection/collectionappraisal/auditRecords',
    collectionappraisal: 'zyd-collection/collectionappraisal/getById/',
    collectionappraisalSave: 'zyd-collection/collectionappraisal/save',
    collectionappraisalPut: 'zyd-collection/collectionappraisal/updateById',
    collectionappraisalBatchExamine: 'zyd-collection/collectionappraisal/batchExamine',
    appraisalexpertExamineList: 'zyd-collection/appraisalexpert/examineList',
    appraisalexpertQueryList: 'zyd-collection/appraisalexpert/queryList',
    docuList: 'zyd-collection/docu/list',
    docuById: 'zyd-collection/docu/getById/',
    docuSave: 'zyd-collection/docu/save',
    docuExport: 'zyd-collection/docu/export',
    docucollectionExport: 'zyd-collection/docucollection/export',
    docuRemoveById: 'zyd-collection/docu/removeById/',
    docucollectionList: 'zyd-collection/docucollection/list',//藏品列表
    relevanceList: "zyd-collection/docu/relevanceList",//关联详情
    collectionrepairList: 'zyd-collection/collectionrepair/list',
    repairResultexport: 'zyd-collection/collectionrepair/repairResultexport',
    collectionrepairSubmit: 'zyd-collection/collectionrepair/submit/',
    collectionrepairAuditRecords: 'zyd-collection/collectionrepair/auditRecords',
    collectionrepairExport: 'zyd-collection/collectionrepair/export',
    collectionrepairRemove: 'zyd-collection/collectionrepair/removeById/',
    collectionrepairSave: 'zyd-collection/collectionrepair/save',
    collectionrepairUpdateById: 'zyd-collection/collectionrepair/updateById',
    collectionrepairById: 'zyd-collection/collectionrepair/getById',
    collectionrepairReviewList: 'zyd-collection/collectionrepair/reviewList',
    collectionrepairExamine: 'zyd-collection/collectionrepair/batchExamine',
    collectionappraisalExamineList: 'zyd-collection/collectionappraisal/examineList',
    listCollectionRevdInfoNextOrPreRepair: 'zyd-collection/collectionrepair/listCollectionRevdInfoNextOrPre',
    repairResultById: 'zyd-collection/collectionrepair/repairResultById',
    repairResultSave: 'zyd-collection/collectionrepair/repairResultSave',
    repairResultBatchSave: 'zyd-collection/collectionrepair/repairResultBatchSave',
    collectionaccidentList: 'zyd-collection/collectionaccident/list',
    collectionaccidentSave: 'zyd-collection/collectionaccident/save',
    collectionaccidentExport: 'zyd-collection/collectionaccident/export',
    collectionaccidentSubmit: 'zyd-collection/collectionaccident/submit/',
    collectionaccidentGetById: 'zyd-collection/collectionaccident/getById',
    collectionaccidentById: 'zyd-collection/collectionaccident/updateById',
    collectionaccidentAuditRecords: 'zyd-collection/collectionaccident/auditRecords',
    collectionaccidentRemoveById: 'zyd-collection/collectionaccident/removeById/',
    collectionaccidentReviewList: 'zyd-collection/collectionaccident/reviewList',
    collectionaccidentBatchExamine: 'zyd-collection/collectionaccident/batchExamine',
    collectionaccidentListCollectionRevdInfoNextOrPre: 'zyd-collection/collectionaccident/listCollectionRevdInfoNextOrPre',
    collectionaccidentAccidentExport: 'zyd-collection/collectionaccident/accidentExport',
    collectiondatacopyList: 'zyd-collection/collectiondatacopy/list',
    collectiondatacopySave: 'zyd-collection/collectiondatacopy/save',
    queryCollectionPage: 'zyd-collection/collectionrepair/queryCollectionPage',
    queryCollectionPage2: 'zyd-collection/collectionrepair/queryCollectionPage2',
    collectionaccidentQueryCollectionPage: 'zyd-collection/collectionaccident/queryCollectionPage',
    collectionaccidentQueryCollectionPage2: 'zyd-collection/collectionaccident/queryCollectionPage2',
    collectionmountList: 'zyd-collection/collectionmount/list',
    getInventoryById: 'zyd-collection/collectionappraisal/getInventoryById/',
    collectionappraisalQueryCollection: 'zyd-collection/collectionappraisal/queryCollection',
    collectionImmovableCulturalRelicSelectListByIds: 'zyd-collection/collectionappraisal/collectionImmovableCulturalRelicSelectListByIds',
    collectionappraisalresultSave: 'zyd-collection/collectionappraisalresult/save',
    collectionappraisalresultUpdateById: 'zyd-collection/collectionappraisalresult/updateById',
    collectionappraisalresultGetById: 'zyd-collection/collectionappraisalresult/getById/',
    collectionappraisalresultExport: 'zyd-collection/collectionappraisalresult/export',
    collectionmountSave: 'zyd-collection/collectionmount/save',
    collectionmountUpdateById: 'zyd-collection/collectionmount/updateById',
    collectionmountSubmitId: 'zyd-collection/collectionmount/submit/',
    collectionmountExport: 'zyd-collection/collectionmount/export',
    collectionmountGetById: 'zyd-collection/collectionmount/getById/',
    collectionmountRecords: 'zyd-collection/collectionmount/auditRecords',
    collectionmountRemoveById: 'zyd-collection/collectionmount/removeById/',
    collectionmountBatchExamine: 'zyd-collection/collectionmount/batchExamine',
    collectionmountReviewList: 'zyd-collection/collectionmount/reviewList',
    collectionmountListCollectionRevdInfoNextOrPre: 'zyd-collection/collectionmount/listCollectionRevdInfoNextOrPre',
    collectionmountQueryCollectionPage: 'zyd-collection/collectionmount/queryCollectionPage',
    collectionmountAccidentExport: 'zyd-collection/collectionmount/accidentExport',
    collectionProtectionList: 'zyd-collection/collectionProtection/list',
    collectionProtectionListExamine: 'zyd-collection/collectionProtection/listExamine',
    collectionProtectionSaveOrUpdateDraft: 'zyd-collection/collectionProtection/saveOrUpdateDraft',
    collectionProtectionSaveOrUpdateSubmit: 'zyd-collection/collectionProtection/saveOrUpdateSubmit',
    collectionProtectionBatchExport: 'zyd-collection/collectionProtection/batchExport',
    collectionappraisalRemoveById: 'zyd-collection/collectionappraisal/removeById/',
    docuUpdateById: 'zyd-collection/docu/updateById',
    collectionProtectionRevd: 'zyd-collection/collectionProtection/revd',
    collectionProtectionBatchSubmit: 'zyd-collection/collectionProtection/batchSubmit',
    collectionProtectionGetById: 'zyd-collection/collectionProtection/getById/',
    collectionProtectionRemoveById: 'zyd-collection/collectionProtection/removeById/',
    collectionProtectionCollectionPage: 'zyd-collection/collectionProtection/queryCollectionPage',
    collectionProtectionCollectionPage2: 'zyd-collection/collectionProtection/queryCollectionPage2',
    collectionProtectionLinkGetById: 'zyd-collection/collectionProtectionLink/getById/',
    collectionProtectionCollectionExport: 'zyd-collection/collectionProtection/collectionExport',
    collectionProtectionLinkUpdateById: 'zyd-collection/collectionProtectionLink/updateById',
    collectionExport: 'zyd-collection/docu/collectionExport',
    exhibitioninfoCollectionExport: 'zyd-collection/exhibitioninfo/collectionExport',
    listRevd: 'zyd-collection/collectiondatacopy/listRevd',
    collectiondatacopyListNextOrPre: 'zyd-collection/collectiondatacopy/listNextOrPre',
    collectiondatacopyListRevdNextOrPre: 'zyd-collection/collectiondatacopy/listRevdNextOrPre',
    searchUserByLike: 'zyd-user/sysuser/searchUserByLike',
    listOutBount: 'zyd-collection/collectionwarehouseorder/listOutBount',
    listOrderReview: 'zyd-collection/collectionwarehouseorder/listOrderReview',
    listOrder: 'zyd-collection/collectionwarehouseorder/listOrder',
    listPendingStorageCollection: 'zyd-collection/collectionwarehouseorder/listPendingStorageCollection',
    collectionwarehouseorder: 'zyd-collection/collectionwarehouseorder/submit',
    getCollectionWarehouseOrderById: 'zyd-collection/collectionwarehouseorder/getCollectionWarehouseOrderById/',
    collectionwarehouseorderGetById: 'zyd-collection/collectionwarehouseorder/getById/',
    getPendingInbountInfo: 'zyd-collection/collectionwarehouseorder/getPendingInbountInfo/',
    pendingHandle: 'zyd-collection/collectionwarehouseorder/pendingHandle',
    handled: 'zyd-collection/collectionwarehouseorder/handled',
    collectionwarehouseorderRevd: 'zyd-collection/collectionwarehouseorder/revd',
    confirmOutbound: 'zyd-collection/collectionwarehouseorder/confirmOutbound',
    listOrderRecode: 'zyd-collection/collectionwarehouseorder/listOrderRecode',
    batchOutBoundListOrderExport: 'zyd-collection/collectionwarehouseorder/batchOutBoundListOrderExport',
    collectionwarehouseorderhandledRemoveById: 'zyd-collection/collectionwarehouseorder/removeById/',
    standingBook: 'zyd-collection/collectionwarehouseorder/standingBook',
    standingBookInBountExport: 'zyd-collection/collectionwarehouseorder/standingBookInBountExport',
    standingBookOutBountExport: 'zyd-collection/collectionwarehouseorder/standingBookOutBountExport',
    collectionrevdListRevd: 'zyd-collection/collectionrevd/listRevd',
    getRevdInfo: 'zyd-collection/collectionrevd/getRevdInfo',
    updateStep: 'zyd-collection/collectionrevd/updateStep',
    enable: 'zyd-collection/collectionrevd/enable',
    batchInBoundListOrderExport: 'zyd-collection/collectionwarehouseorder/batchInBoundListOrderExport',
    listInBount: 'zyd-collection/collectionwarehouseorder/listInBount',
    inbound: 'zyd-collection/collectionwarehouseorder/inbound',
    collectionwarehouseorderPendingHandle: 'zyd-collection/collectionwarehouseorder/pendingHandle',
    collectionwarehouseorderHandled: 'zyd-collection/collectionwarehouseorder/handled',
    collectionwarehouseorderInbound: 'zyd-collection/collectionwarehouseorder/inbound',
    exportCollection: 'zyd-collection/collectiondisinfect/exportCollection',
    getArchivesDocHistoryByCreateBy: 'zyd-collection/archivesdochistory/getArchivesDocHistoryByCreateBy',
    cleanArchivesDocHistory: 'zyd-collection/archivesdochistory/cleanArchivesDocHistory',
    archivesdochistoryRemoveById: 'zyd-collection/archivesdochistory/removeById/',
    queryDatabaseSearchPage: 'zyd-collection/archivesdata/queryDatabaseSearchPage',
    archivespersonalfavoritesSave: 'zyd-archives/archivespersonalfavorites/save',
    commonList: 'zyd-zgww/thematiclibrary/commonList',
    thematiclibraryresourceBatchSave: 'zyd-zgww/thematiclibraryresource/batchSave',
    collectionType: 'zyd-collection/collectionDataBoard/collectionType',
    collectionDataStoreCount: 'zyd-collection/collectionDataBoard/collectionDataStoreCount',
    collectYearCount: 'zyd-collection/collectionDataBoard/collectYearCount',
    collectTotalCount: 'zyd-collection/collectionDataBoard/collectTotalCount',
    favoritesRank: 'zyd-collection/collectionDataBoard/favoritesRank',
    identifiedCount: 'zyd-collection/collectionDataBoard/identifiedCount',
    collectionDataCount: 'zyd-collection/collectionDataBoard/collectionDataCount',
    listPendingInbount: 'zyd-collection/collectionwarehouseorder/listPendingInbount',
    collectionwarehouseorderOutbound: 'zyd-collection/collectionwarehouseorder/outbound',
    listItemOrderApplication: 'zyd-collection/collectionwarehouseorder/listItemOrderApplication',
    listItemOutBount: 'zyd-collection/collectionwarehouseorder/listItemOutBount',
    collectionwarehouseorderListItemInBount: 'zyd-collection/collectionwarehouseorder/listItemInBount',
    collectionLogoff: 'zyd-collection/collectionDataBoard/collectionLogoff',
    intelligentProcessing:'zyd-collection/archivesdata/intelligentProcessing',
    getUploadImg:'zyd-collection/collectionmount/getUploadImg',
    uploadImg:'zyd-collection/collectionmount/uploadImg',
    getByCode:'zyd-common/businesssetting/getByCode',
    collectionappraisaldataList:'zyd-collection/collectionappraisaldata/list',
    collectionappraisaldataUpdateById:'zyd-collection/collectionappraisaldata/updateById',
    collectionappraisalresultBatchSave:'zyd-collection/collectionappraisalresult/batchSave',
    collectionappraisalresult:'zyd-collection/collectionappraisalresult/downloads',
    getCollectionById:'zyd-collection/collectionappraisal/getCollectionById/',
    collectionfileListDyygFile:'zyd-collection/collectionfile/listDyygFile',
    collectionfileMasterPic:'zyd-collection/collectionfile/masterPic',
    collectionfileCancelMasterPic:'zyd-collection/collectionfile/cancelMasterPic',
    collectionfileUrlDyygZip:'zyd-collection/collectionfile/urlDyygZip',
    urlZmZip:'zyd-collection/collectionfile/urlZmZip',
    listZmByDataId:'zyd-collection/collectionfile/listZmByDataId',
    listThreeFileByDataId:'zyd-collection/collectionfile/listThreeFileByDataId',
    urlThreeZip:'zyd-collection/collectionfile/urlThreeZip',
    individualCollections:'zyd-collection/collectionrepair/individualCollections',
    collectionrepairListAll2:'zyd-collection/collectionrepair/listAll2',
    collectionrepairBatchDownload:'zyd-collection/collectionrepair/batchDownload',
    individualCollectionsById:'zyd-collection/collectionrepair/individualCollectionsById',
    collectionmountIndividualCollections:'zyd-collection/collectionmount/individualCollections',
    collectionmountBatchDownload:'zyd-collection/collectionmount/batchDownload',
    collectionmountGetMountImgPage:'zyd-collection/collectionmount/getMountImgPage',
    collectiondataListLink:'zyd-collection/collectiondata/listLink',
    docuCollctionInfolist:'zyd-collection/docu/collctionInfolist',
    docuGetInfoById:'zyd-collection/docu/getInfoById',
    docuExportExcel:'zyd-collection/docu/exportExcel',
    docuInfoExportDownload:'zyd-collection/docu/infoExportDownload',
    collectionaccidentListAll:'zyd-collection/collectionaccident/listAll',
    collectionDataAnalysis:'zyd-collection/collectionDataBoard/collectionDataAnalysis',
    collectionGeneralDataAnalysis:'zyd-collection/collectionDataBoard/collectionGeneralDataAnalysis',
    collectionaccidentExport:'zyd-collection/collectionaccident/export',
    batchDownload:'zyd-collection/collectionaccident/batchDownload',
    getMountImgPage:'zyd-collection/collectionaccident/getMountImgPage',
    collectionaccidentListAll2:'zyd-collection/collectionaccident/listAll2',
    collectionType2:'zyd-collection/collectionDataBoard/collectionType2',
    getArchivesBorrowCount:'zyd-archives/archivesborrow/getArchivesBorrowCount',
    exportCopyModel:'zyd-collection/archivesdataoperation/exportCopyModel',
    collectionProtectionBatchDownload:'zyd-collection/collectionProtection/batchDownload',
    collectionProtectionGetMountImgPage:'zyd-collection/collectionProtection/getMountImgPage',
    collectionrevdprocessList:'zyd-collection/collectionrevdprocess/list',
    collectionTotalCount:'zyd-collection/collectionDataBoard/collectionTotalCount',
    collectionResourceTotalCount:'zyd-collection/collectionDataBoard/collectionResourceTotalCount',
    collectionResourceCountByMouth:'zyd-collection/collectionDataBoard/collectionResourceCountByMouth',
    collectionResourceCount:'zyd-collection/collectionDataBoard/collectionResourceCount',
    getArchivesBasicDataCount:'zyd-archives/archivesdata/getArchivesBasicDataCount',
    collectionfileListDyygOtherFile:'zyd-collection/collectionfile/listDyygOtherFile',
    collectionfileExportOtherFile:'zyd-collection/collectionfile/exportOtherFile',
    collectionfileDownloadOtherFile:'zyd-collection/collectionfile/downloadOtherFile',
}

// 数字资源
let digital = {
    dyygintellectualpropertyList: 'zyd-zgww/dyygintellectualproperty/list',
    dyygintellectualpropertySave: 'zyd-zgww/dyygintellectualproperty/save',
    dyygintellectualpropertyUpdateById: 'zyd-zgww/dyygintellectualproperty/updateById',
    dyygintellectualpropertyRemoveById: 'zyd-zgww/dyygintellectualproperty/removeById',
    propertyOwnerList: 'zyd-zgww/dyygintellectualproperty/propertyOwnerList',
    originalAuthorList: 'zyd-zgww/dyygintellectualproperty/originalAuthorList',
    dyygintellectualpropertyGetById: 'zyd-zgww/dyygintellectualproperty/getById',
    enableWatermark: 'zyd-zgww/dyygintellectualproperty/enableWatermark',
    dyygintellectualpropertyDownload: 'zyd-zgww/dyygintellectualproperty/download',
    downloadSetting: 'zyd-zgww/downloadSetting/list',
    downloadSettingUpdateById: 'zyd-zgww/downloadSetting/updateById',
    appraisalrecode: 'zyd-zgww/appraisalrecode/list',
    removeById:'zyd-zgww/appraisalrecode/removeById/',
    updateById:'zyd-zgww/appraisalrecode/updateById',
    updataResourcesType: 'zyd-zgww/resources/updataResourcesType',
    resourceFileDownloadLog: 'zyd-zgww/resourceFileDownloadLog/list',
    resourcelabellink: 'zyd-zgww/resourcelabellink/updateById',
    exhibitionTeamSave: "zyd-zgww/ExhibitionTeam/save",
    getResourceFilesWatermark: "zyd-zgww/resourceFile/getResourceFilesWatermark",
    batchRemove: "zyd-zgww/resourcelabellink/batchRemove",
    downThreeZip: "zyd-zgww/resources/downThreeZip",
}

// 订单管理
let order = {
    updateByTicketingPromptConfig: 'zyd-ticketing/ticketingpromptconfig/updateByTicketingPromptConfig', // 修改预约内容
    getByTicketingPromptConfig: 'zyd-ticketing/ticketingpromptconfig/getByTicketingPromptConfig', // 获取预约内容配置

    getByTicketingMakeConfig: 'zyd-ticketing/ticketingmakeconfig/getByTicketingMakeConfig', // 获取预约规则配置
    updateByTicketingMakeConfig: 'zyd-ticketing/ticketingmakeconfig/updateByTicketingMakeConfig', // 修改预约规则配置

    getTicketingStorehouseConfig: 'zyd-ticketing/ticketingstorehouseconfig/getTicketingStorehouseConfig', // 获取票仓配置
    saveTicketingStorehouseConfig: 'zyd-ticketing/ticketingstorehouseconfig/saveTicketingStorehouseConfig', // 保存票仓配置规则

    getByDayTicketingStorehouse: 'zyd-ticketing/ticketingstorehouse/getByDayTicketingStorehouse', // 获取指定日期票仓数据
    buildDiyTicketingStorehouse: 'zyd-ticketing/ticketingstorehouse/buildDiyTicketingStorehouse', // 指定日期创建票仓数据
    restoreDefaultTicketingStorehouse: 'zyd-ticketing/ticketingstorehouse/restoreDefaultTicketingStorehouse', // 指定日期恢复默认票仓
    getByYearsTicketingStorehouse: 'zyd-ticketing/ticketingstorehouse/getByYearsTicketingStorehouse', // 指定月份获取票仓数据

    getByYearsTicketingClose: 'zyd-ticketing/ticketingclose/getByYearsTicketingClose', // 指定月份获取闭馆数据
    importTicketingclose: 'zyd-ticketing/ticketingclose/import', // excel导入开馆、闭馆日期
    saveOpenTicketing: 'zyd-ticketing/ticketingclose/saveOpenTicketing', // 新增开馆数据
    saveTicketingClose: 'zyd-ticketing/ticketingclose/saveTicketingClose', // 新增闭馆数据

    sysGetTicketingMakeDetail: 'zyd-ticketing/ticketingmake/sysGetTicketingMakeDetail', // 后台参观列表
    sysGetTicketingMake: 'zyd-ticketing/ticketingmake/sysGetTicketingMake', // 后台订单列表
    sysGetTicketingMakeById: 'zyd-ticketing/ticketingmake/sysGetTicketingMakeById', // 后台根据预约ID获取详情
    sysGetTicketingBlack: 'zyd-ticketing/ticketingblack/sysGetTicketingBlack', // 黑名单列表
    addBlackDay: 'zyd-ticketing/ticketingblack/addBlackDay', // 黑名单加时长
    releaseTicketingBlack: 'zyd-ticketing/ticketingblack/releaseTicketingBlack', // 黑名单释放
    getBaseMakeBlack: 'zyd-ticketing/basemakeblack/getBaseMakeBlack', // 获取黑名单配置
    basemakeblackSave: 'zyd-ticketing/basemakeblack/save', // 保存黑名单配置
    sysGetTicketingBlackById: 'zyd-ticketing/ticketingblack/sysGetTicketingBlackById', // 黑名单详情
    sysGetTicketingBreakAppointment: 'zyd-ticketing/ticketingbreakappointment/sysGetTicketingBreakAppointment', // 后台查看爽约记录
    sysGetTicketingBreakAppointmentDetail: 'zyd-ticketing/ticketingbreakappointment/sysGetTicketingBreakAppointmentDetail', // 爽约记录查看详情
    downLoadTempExcel: 'zyd-ticketing/ticketingclose/downLoadTempExcel', // 下载闭馆日期模版
    sysGetTicketingServer: 'zyd-ticketing/ticketingserver/sysGetTicketingServer', // 后台&小程序查询服务列表
    sysGetTicketingServerById: 'zyd-ticketing/ticketingserver/sysGetTicketingServerById', // 后台&小程序查询服务详情
    allNumberOfPeople: 'zyd-ticketing/ticketingnumberofpeople/allNumberOfPeople', // 获取所有人数
    allAge: 'zyd-ticketing/ticketingage/allAge', // 获取所有年龄
    allLanguage: 'zyd-ticketing/ticketinglanguage/allLanguage', // 获取所有语言
    sysSaveTicketingServer: 'zyd-ticketing/ticketingserver/sysSaveTicketingServer', // 后台编辑保存服务
    sysGetTicketingNumberOfPeople: 'zyd-ticketing/ticketingnumberofpeople/sysGetTicketingNumberOfPeople', // 后台人数类型列表
    saveTicketingnumberofpeople: 'zyd-ticketing/ticketingnumberofpeople/sysSave',
    updateTicketingnumberofpeopleById: 'zyd-ticketing/ticketingnumberofpeople/sysUpdateById',
    batchRemoveTicketingNumberOfPeople: 'zyd-ticketing/ticketingnumberofpeople/batchRemoveTicketingNumberOfPeople',
    sysGetTicketingLanguage: 'zyd-ticketing/ticketinglanguage/sysGetTicketingLanguage',
    saveTicketinglanguage: 'zyd-ticketing/ticketinglanguage/sysSave',
    updateTicketinglanguageById: 'zyd-ticketing/ticketinglanguage/sysUpdateById',
    batchRemoveTicketingLanguage: 'zyd-ticketing/ticketinglanguage/batchRemoveTicketingLanguage',
    sysGetTicketingAge: 'zyd-ticketing/ticketingage/sysGetTicketingAge',
    saveTicketingage: 'zyd-ticketing/ticketingage/sysSave',
    updateTicketingageById: 'zyd-ticketing/ticketingage/sysUpdateById',
    batchRemoveTicketingAge: 'zyd-ticketing/ticketingage/batchRemoveTicketingAge',
    sysGetTicketingServerOrder: 'zyd-ticketing/ticketingserverorder/sysGetTicketingServerOrder',
    sysGetTicketingServerOrderById: 'zyd-ticketing/ticketingserverorder/sysGetTicketingServerOrderById',
    updateTicketingServerState: 'zyd-ticketing/ticketingserver/updateTicketingServerState',
    sysGetTicketingMakeTeam: 'zyd-ticketing/ticketingmaketeam/sysGetTicketingMakeTeam',
    getTicketingMakeTeamById: 'zyd-ticketing/ticketingmaketeam/getTicketingMakeTeamById',
    getTicketingMakeDetailById: 'zyd-ticketing/ticketingmaketeam/getTicketingMakeDetailById',
    ticketingMakeTeamToExamine: 'zyd-ticketing/ticketingmaketeam/ticketingMakeTeamToExamine',
    // 大数据看板
    sysStatisticsMakeDetailToDay: 'zyd-ticketing/ticketingmakedetail/sysStatisticsMakeDetailToDay',
    sysStatisticsServerOrderToDay: 'zyd-ticketing/ticketingserverorderdetail/sysStatisticsServerOrderToDay',
    sysStatisticsActivityToDay: 'zyd-activity/activityinfo/sysStatisticsActivityToDay',
    sysStatisticsMakeDetail: 'zyd-ticketing/ticketingmakedetail/sysStatisticsMakeDetail',
    sysStatisticsSex: 'zyd-ticketing/ticketingmakedetail/sysStatisticsSex',
    sysStatisticsAddress: 'zyd-ticketing/ticketingmakedetail/sysStatisticsAddress',
    sysStatisticsServerDetail: 'zyd-ticketing/ticketingserverorderdetail/sysStatisticsServerDetail',
    sysStatisticsActivity: 'zyd-activity/activityinfo/sysStatisticsActivity',
    serverOrderExPortList: 'zyd-ticketing/ticketingserverorder/exPortList',
    teamOrderExPortList: 'zyd-ticketing/ticketingmaketeam/exPortList',
}

// 湘绣官网
let website = {
    websitebanner: 'zyd-website/websitebanner/list',
    batchRemove: 'zyd-website/websitebanner/batchRemove',
    websitebannerSave: 'zyd-website/websitebanner/save',
    websitebannerUpdateById: 'zyd-website/websitebanner/updateById',
    websitequestionnaire: 'zyd-website/websitequestionnaire/list',
    questionBatchRemove: 'zyd-website/websitequestionnaire/batchRemove',
    websitequestionnaireSave: 'zyd-website/websitequestionnaire/save',
    websitequestionnaireGetById: 'zyd-website/websitequestionnaire/getById',
    openWebsiteQuestionnaire: 'zyd-website/websitequestionnaire/openWebsiteQuestionnaire',
    websiteuserquestionnaire: 'zyd-website/websiteuserquestionnaire/list',
    websiteuserquestionnaireGetById: 'zyd-website/websiteuserquestionnaire/getById',
    websitenewscategory: 'zyd-website/websitenewscategory/list',
    websitenewscategorySave: 'zyd-website/websitenewscategory/save',
    websitenewscategoryUpdateById: 'zyd-website/websitenewscategory/updateById',
    websitenewscategoryBatchRemove: 'zyd-website/websitenewscategory/batchRemove',
    websiteannouncementcategory: 'zyd-website/websiteannouncementcategory/list',
    websiteannouncementcategorySave: 'zyd-website/websiteannouncementcategory/save',
    websiteannouncementcategoryUpdateById: 'zyd-website/websiteannouncementcategory/updateById',
    websiteannouncementcategoryBatchRemove: 'zyd-website/websiteannouncementcategory/batchRemove',
    sysGetWebsiteJournalism: 'zyd-website/websitejournalism/sysGetWebsiteJournalism',
    reviewedWebsiteJournalism: 'zyd-website/websitejournalism/reviewedWebsiteJournalism',
    websitejournalismBatchRemove: 'zyd-website/websitejournalism/batchRemove',
    websitejournalismUpdateById: 'zyd-website/websitejournalism/updateById',
    websitejournalismUpdateEnableById: 'zyd-website/websitejournalism/updateEnableById',
    websitejournalismSave: 'zyd-website/websitejournalism/save',
    websitenoticeannouncement: 'zyd-website/websitenoticeannouncement/list',
    websitenoticeannouncementSave: 'zyd-website/websitenoticeannouncement/save',
    reviewedWebsiteNoticeAnnouncement: 'zyd-website/websitenoticeannouncement/reviewedWebsiteNoticeAnnouncement',
    websitenoticeannouncementUpdateById: 'zyd-website/websitenoticeannouncement/updateById',
    websitenoticeannouncementUpdateEnableById: 'zyd-website/websitenoticeannouncement/updateEnableById',
    websitenoticeannouncementBatchRemove: 'zyd-website/websitenoticeannouncement/batchRemove',
    getWebsiteBriefIntroductionAddress: 'zyd-website/websitebriefintroductionaddress/getWebsiteBriefIntroductionAddress',
    speech: 'zyd-website/websitebriefintroductionaddress/saveAddress',
    introduction: 'zyd-website/websitebriefintroductionaddress/saveBriefIntroduction',
    openTime: 'zyd-website/websitebriefintroductionaddress/saveOpenContent',
    visitNotice: 'zyd-website/websitebriefintroductionaddress/saveNoticeToVisitorsContent',
    traffic: 'zyd-website/websitebriefintroductionaddress/saveMassTransit',
    contactUs: 'zyd-website/websitebriefintroductionaddress/saveContactUs',
    regulations: 'zyd-website/websitebriefintroductionaddress/saveNoticeToVisitors',
    teamNotice: 'zyd-website/websitebriefintroductionaddress/saveTicketBookingInstructions',
    ticketingteamtype: 'zyd-ticketing/ticketingteamtype/list',
    batchRemoveTicketingTeamType: 'zyd-ticketing/ticketingteamtype/batchRemoveTicketingTeamType',
    ticketingteamtypeSave: 'zyd-ticketing/ticketingteamtype/save',
    updateTicketingTeamTypeById: 'zyd-ticketing/ticketingteamtype/updateTicketingTeamTypeById',
    websiteworkingpersonneljob: 'zyd-website/websiteworkingpersonneljob/list',
    websiteworkingpersonneljobBatchRemove: 'zyd-website/websiteworkingpersonneljob/batchRemove',
    websiteworkingpersonneljobSave: 'zyd-website/websiteworkingpersonneljob/save',
    websiteworkingpersonneljobUpdateById: 'zyd-website/websiteworkingpersonneljob/updateById',
    websitehistory: 'zyd-website/websitehistory/list',
    websitehistorySave: 'zyd-website/websitehistory/save',
    websitehistoryBatchRemove: 'zyd-website/websitehistory/batchRemove',
    websitehistoryUpdateById: 'zyd-website/websitehistory/updateById',
    websiteworkingpersonnel: 'zyd-website/websiteworkingpersonnel/list',
    websiteworkingpersonnelBatchRemove: 'zyd-website/websiteworkingpersonnel/batchRemove',
    websiteworkingpersonnelSave: 'zyd-website/websiteworkingpersonnel/save',
    websiteworkingpersonnelUpdateById: 'zyd-website/websiteworkingpersonnel/updateById',
    allWebsiteNewsCategory: 'zyd-website/websitenewscategory/allWebsiteNewsCategory',
    allWebsiteAnnouncementCategory: 'zyd-website/websiteannouncementcategory/allWebsiteAnnouncementCategory',
    sysGetCreateUser: 'zyd-website/websitequestionnaire/sysGetCreateUser',
    allWebsiteWorkingPersonnelJob: 'zyd-website/websiteworkingpersonneljob/allWebsiteWorkingPersonnelJob',
    sysGetWebsiteBill: 'zyd-website/websitebill/sysGetWebsiteBill',
    updateApplicationStatus: 'zyd-website/websitebill/updateApplicationStatus',
}

// 活动接口
let activity = {
    // 活动报名管理
    sysGetActivityApply: 'zyd-activity/activityapply/sysGetActivityApply',
    queryByCourseSession: 'zyd-activity/activityinfo/queryByCourseSession',
    activityinfo: 'zyd-activity/activityinfo/all',
    sysGetActivityApplyById: 'zyd-activity/activityapply/sysGetActivityApplyById',
    getSysActivityApplyDetailById: 'zyd-activity/activityapply/getSysActivityApplyDetailById',
    returnActivityApplyDetail: 'zyd-activity/activityapplydetail/sysReturnActivityApplyDetail',

    //摆错位置了（活动黑名单的）
    releaseBlack: 'zyd-activity/blacklist/releaseBlack/',
    getByIdBlackSession: 'zyd-activity/blacklist/getByIdBlackSession',
    getByIdPullBlackDetail: 'zyd-activity/blacklist/getByIdPullBlackDetail',
    pullBlackMessage: 'zyd-activity/blacklist/pullBlackMessage',
    list: 'zyd-activity/balckrule/list',
    batchRuleUpdateById: 'zyd-activity/balckrule/batchRuleUpdateById',//修改黑名单规则接口
}

//活动管理接口
let activitymanagement = {
    activityList: "zyd-activity/activityinfo/list",
    modifyState: "zyd-activity/activityinfo/updateByActivityInfo",
    activityDetails: "zyd-activity/activityinfo/backstageGetById/",
    newActivities: "zyd-activity/activityinfo/save",
    modifyActivity: "zyd-activity/activityinfo/updateById",
    seriesList: "zyd-activity/series/list",
    idGetSeriesInformation: "zyd-activity/series/getById/",
    modifySeries: "zyd-activity/series/updateById",
    addSeries: "zyd-activity/series/save",
    tagListRequest: "zyd-activity/label/list",
    newLabel: "zyd-activity/label/save",
    idGetTag: "zyd-activity/label/getById/",
    modifyLabel: "zyd-activity/label/updateById",
    enrollmentManagementList: "zyd-activity/activityapply/list",
    allactivity: "zyd-activity/activityinfo/all",
    delete: 'zyd-activity/activityinfo/removeById/',
    listSeriesActivities: 'zyd-activity/series/queryActivityList',
    teamEnrollmentList: 'zyd-activity/activityapplyteam/list',
    messagePush: 'zyd-common/basemessage/messageContent',
    activitymessagePush: 'zyd-activity/activityinfo/pushMessage',
    getContent: 'zyd-activity/activityinfo/activityLabelSeries',
    backstageGetInfoCount: 'zyd-activity/activityinfo/backstageGetInfoCount',
    exPortList: 'zyd-activity/activityapply/exPortList',
    createSignCode: 'zyd-activity/activityinfo/createSignCode',

    getPreviewMessages: 'zyd-activity/activityinfo/preview',
    pushBanner: 'zyd-activity/activityinfo/pushBanner',
    queryCopy: 'zyd-activity/activityinfo/backstageGetById/',
    blackList: 'zyd-activity/blacklist/list',
    pullBlack: 'zyd-activity/blacklist/pullBlack',
    continueBlackTime: 'zyd-activity/blacklist/continueBlackTime',
    supplementaryBySignature: 'zyd-activity/activityapply/supplementaryBySignature',
    backstageGetById: 'zyd-activity/activityinfo/backstageGetById/',
    singleMessageSave: 'zyd-activity/single/singleMessageSave',
    findActivitySysLogListById: 'zyd-activity/activitysyslog/findActivitySysLogListById',

    updateByIdRelease: 'zyd-activity/activityinfo/updateByIdRelease',
    updateByActivityTop: 'zyd-activity/activityinfo/updateByActivityTop',
    updateByActivityInfo: 'zyd-activity/activityinfo/updateByActivityTop',
}

//H5权限管理
let authority = {
    allRoles: 'zyd-user/baseuserrole/all',//所有角色
    userAssociationList: 'zyd-user/baseuserandrole/list',
    newlyAdded: 'zyd-user/baseuserandrole/save',
    modificationManagement: 'zyd-user/baseuserandrole/updateById',
    roleList: 'zyd-user/baseuserrole/list',
    addRole: 'zyd-user/baseuserrole/save',
    modifyRole: 'zyd-user/baseuserrole/updateById',
    deleteRole: 'zyd-user/baseuserrole/removeById/',
    deleassociated: 'zyd-user/baseuserandrole/removeById/',
}

//证件管理
let registrationMethod = {
    list: 'zyd-common/cardmanagement/list',
    save: 'zyd-common/cardmanagement/save',
    updateById: 'zyd-common/cardmanagement/updateById',
    batchRemove: 'zyd-common/cardmanagement/batchRemove',
    all: 'zyd-common/cardmanagement/all',
    findActivityCardTypeById: 'zyd-common/cardmanagement/findActivityCardTypeById',
    backstageCancelApply: 'zyd-common/activityapply/backstageCancelApply',
}

//意见反馈
let feedback = {
    list: "zyd-website/websiteFeedback/list",
    getById: "zyd-website/websiteFeedback//getById/",
    removeById: "zyd-website/websiteFeedback/removeById/",
}

//数字产品
let digitalProducts = {
    list: 'zyd-website/websitedigitalproducts/list',
    updateById: 'zyd-website/websitedigitalproducts/updateById',
    batchRemove: 'zyd-website/websitedigitalproducts/batchRemove',
    save: 'zyd-website/websitedigitalproducts/save',
    getById: 'zyd-website/websitedigitalproducts/getById/',
}

//服务设施
let installation = {
    save: 'zyd-website/websiteservicefacilities/save',
    updateById: 'zyd-website/websiteservicefacilities/updateById',
    list: 'zyd-website/websiteservicefacilities/list',
    batchRemove: 'zyd-website/websiteservicefacilities/batchRemove',
    getById: 'zyd-website/websiteservicefacilities/getById/',
}
// 以上湘绣接口api


// 以下展览展示接口api
// 流程表单接口
let extension = {
    formCategoryTreeData: 'formCategory/treeData',
    formCategoryDelete: 'formCategory/delete',
    formDefinitionList: 'formDefinition/list',
    formDefinitionDelete: 'formDefinition/delete',
    formDefinitionQueryById: 'formDefinition/queryById',
    formDefinitionQueryByJsonId: 'formDefinition/queryByJsonId',
    formDefinitionSave: 'formDefinition/save',
    formDefinitionJsonQueryById: 'formDefinitionJson/queryById',
    formDefinitionJsonSave: 'formDefinitionJson/save',
    formDefinitionJsonList: 'formDefinitionJson/list',
    formDefinitionJsonDelete: 'formDefinitionJson/delete',
    formDefinitionJsonUpdatePrimary: 'formDefinitionJson/updatePrimary',
    formCategoryQueryById: 'formCategory/queryById',
    formCategorySave: 'formCategory/save',
    actCategoryTreeData: 'actCategory/treeData',
    queryByDefIdAndTaskId: 'taskDefExtension/queryByDefIdAndTaskId',
    flowCopySave: 'flowCopy/save',
    actCategorySave: 'actCategory/save',
    actCategoryQueryById: 'actCategory/queryById',
    actCategoryDelete: 'actCategory/delete',
    buttonQueryById: 'button/queryById',
    buttonSave: 'button/save',
    buttonList: 'button/list',
    buttonDelete: 'button/delete',
    conditionQueryById: 'condition/queryById',
    conditionSave: 'condition/save',
    conditionDelete: 'condition/delete',
    conditionList: 'condition/list',
    flowCopyList: 'flowCopy/list',
    flowCopyDelete: 'flowCopy/delete',
    listenerSave: 'listener/save',
    listenerQueryById: 'listener/queryById',
    listenerDelete: 'listener/delete',
    listenerList: 'listener/list',
}

let flowable = {
    formSubmitStartFormData: 'form/submitStartFormData',
    formSubmitTaskFormData: 'form/submitTaskFormData',
    getStartFormData: 'form/getStartFormData',
    getTaskFormData: 'form/getTaskFormData',
    getHistoryTaskFormData: 'form/getHistoryTaskFormData',
    modelUpdateCategory: 'model/updateCategory',
    modelCopy: 'model/copy',
    modelDelete: 'model/delete',
    modelDeploy: 'model/deploy',
    modelList: 'model/list',
    processList: 'process/list',
    updateActive: 'process/update/active',
    processDelete: 'process/delete',
    updateSuspend: 'process/update/suspend',
    convertToModel: 'process/convert/toModel',
    deleteAllProcIns: 'process/history/deleteAllProcIns',
    historyListData: 'process/historyListData',
    deleteProcIns: 'process/deleteProcIns',
    runningData: 'process/runningData',
    stop: 'process/stop',
    revokeProcIns: 'process/revokeProcIns',
    queryProcessStatus: 'process/queryProcessStatus',
    getTaskDef: 'task/getTaskDef',
    myApplyed: 'task/myApplyed',
    callback: 'task/callback',
    historic: 'task/historic',
    start: 'task/start',
    backNodes: 'task/backNodes',
    back: 'task/back',
    addSignTask: 'task/addSignTask',
    transfer: 'task/transfer',
    delegate: 'task/delegate',
    audit: 'task/audit',
    unclaim: 'task/unclaim',
    claim: 'task/claim',
    todo: 'task/todo',
    historicTaskList: 'task/historicTaskList',
}

let mana = {//目录
    list: "mana/list",//根据展览id查询目录信息
    listname: "mana/listname",//根据展览id查询目录信息
    selectSysDirectory: "mana/selectSysDirectory2",//根据展览id查询所有目录
    selectSysDirectory3: "mana/selectSysDirectory3",//根据展览id查询所有目录,有归档文件的目录
    queryAllByLimit: "mana/queryAllByLimit",//列表
    directorySave: "mana/save",//新增
    directoryUpdateById: "mana/updateById",//修改
    directoryRemoveById: "mana/removeById",//删除
    getTemplate: "mana/getTemplate",//套用模板
    setTemplate: "mana/setTemplate",//设为模板
}

let catalogue = {
    //著录项管理
    originalDescriptionList: "OriginalDescription/querySysOriginalDescriptionByLimit",//列表
    tableDate: "OriginalDescription/tableDate",//文件表信息查询
    originalDescriptionSave: "OriginalDescription/save",//保存
    originalDescriptionUpdateById: "OriginalDescription/updateById",//修改
    originalDescriptionRemoveById: "OriginalDescription/removeById",//删除
    querySysOriginalDescriptionAll: "OriginalDescription/querySysOriginalDescriptionAll",//查询所有

    //档号规则
    rulesList: "Rules/queryAll",
    rulesSave: "Rules/save",
    rulesRemoveById: "Rules/removeById",
    rulesUpdateById: "Rules/updateById",

}
//全文检索
let docsearch = {
    searchDoc: "zyd-catalogue/doc/searchDoc",
}

//报表管理接口
let report = {
    //ftp
    ftpServiceList: "FtpService/queryAllByLimit",//分页查询
    ftpServiceSave: "FtpService/save",//保存
    ftpServiceUpdateById: "FtpService/updateById",//修改
    ftpServiceRemoveById: "FtpService/removeById",//删除
    ftpServiceAll: "FtpService/queryAll",//查询所有

    //报表模板
    templateList: "Template/queryAllByLimit",//分页查询
    templateById: "Template/queryById",//根据id查询
    templateSave: "Template/save",//保存
    templateUpdateById: "Template/updateById",//修改
    templateRemoveById: "Template/removeById",//删除
    templateAll: "Template/queryAll",//查询所有

}

//系统管理模块接口
let infor = {//展览
    exhibitionInDirectory: "mation/ExhibitionInDirectory",//所有展览目录信息
    exhibitionInDirectoryAll: "mation/queryAll",//所有展览目录信息
    exhibitionInall: "mation/exhibitionInall",//所有展览信息
    // exhibitionList:"mation/list",//展览列表
    informationList: "mation/queryAllByLimit", // 展览信息查询所有
    informationRemove: "mation/removeById", // 展览信息删除
    informationQuery: "mation/queryById", // 展览信息id查询
    informationAdd: "mation/save", // 展览信息新曾
    informationCardAdd: "mation/CardAdd", // 展览卡片信息
    informationByCard: "mation/queryAllByCardByLimit", // 展览卡片信息集合
    informationByCard1: "mation/queryAllByCardByLimit1", // 展览卡片信息集合
    informationByCardHome: "mation/queryAllByCardByLimitHome", // 展览卡片信息集合
    informationQueryCount: "mation/queryCount", // 查询已归档展览总数
    informationQueryTotalExhibition: "mation/queryTotalExhibition", // 查询已归档展览总数

    informationEdit: "mation/updateById", // 展览信息修改
    queryShowCount: "mation/queryShowCount",//查询显示首页数
    //展览用户
    selectSysExhibitionAuthorityAll: "Authority/selectSysExhibitionAuthorityAll",//重新所有用户
    save: "Authority/save",//保存展览用户
    updateById: "Authority/updateById",//修改展览用户

    //展览链接
    SysExhibitionLinkList: "SysExhibitionLink/queryById",
    SysExhibitionLinkSave: "SysExhibitionLink/save",

    //展览卡片
    SysExhibitionCardList: "SysExhibitionCard/queryById",
    SysExhibitionCardSave: "SysExhibitionCard/save",

    //目录模板
    catalogTemplateList: "CatalogTemplate/queryAllByLimit",//列表
    catalogTemplateAll: "CatalogTemplate/queryAll",//查询所有
    catalogTemplateSave: "CatalogTemplate/save",//保存
    catalogTemplateRemoveById: "CatalogTemplate/removeById",//删除
    catalogTemplateCopy: "CatalogTemplate/copy",//复制
    catalogTemplateUpdateById: "CatalogTemplate/updateById",//修改
    queryAllByTemplateType: "CatalogTemplate/queryAllByTemplateType",//查询所有档号规则

    //目录模板数据
    SysCatalogTemplateDataList: "SysCatalogTemplateData/queryAllByLimit",//列表
    SysCatalogTemplateDataSave: "SysCatalogTemplateData/save",//保存
    SysCatalogTemplateDataUpdate: "SysCatalogTemplateData/updateById",//修改
    SysCatalogTemplateDataRemove: "SysCatalogTemplateData/removeById",//删除

}

//公共模块接口
let common = {
    //上传文件
    fileUpload: "File/upload",//用户相关上传
    exhibitionUpload: "File/exhibitionUpload",//展览上传
    getPercentage: "File/getPercentage",//获取已上传文件进度
    deleteByUrl: "File/deleteByUrl",//删除文件
    download: "File/download",//下载

    //日志
    logFindList: "Log/findList",//日志查询
    logSave: "Log/logSave",//新增日志
    batchInsertLogin: "Log/batchInsertLogin",//批量新增日志

    //文件收集
    fileCollectSave: "FileCollect/save",//新增文件
    fileCollectCompressPic: "FileCollect/compressPic",//新增文件
    fileCollectList: "FileCollect/queryAllByLimit",//已完成列表
    fileCollectUpdateById: "FileCollect/updateById",//修改
    fileCollectDeleteReduction: "FileCollect/deleteReduction",//修改
    fileCollectRestore: "Reorganize/restore",//修改
    fileCollectRemoveById: "FileCollect/removeById",//删除
    fileCollectEmptyRecycle: "FileCollect/emptyRecycle",//清空回收站
    fileCollectQueryById: "FileCollect/queryById",//根据id查询
    fileCollectQueryCount: "FileCollect/queryCount",//查询已整编未删除的文件总数
    lock: "FileCollect/lock",

    //文件整编
    queryAllByLimitReorganize: "Reorganize/queryAllByLimitReorganize",//列表
    queryAllByLimitAssociated: "Reorganize/queryAllByLimitAssociated",//未关联列表
    fileCollectBatchUpdateById: "FileCollect/batchUpdateById",//批量修改
    fileCollectExport: "Reorganize/export",//导出目录
    versionReorganize: "Reorganize/versionReorganize",//版本查询
    FileVersionHistoryBatchInsert: "FileVersionHistory/batchInsert",//批量新增版本
    FileVersionHistorySave: "FileVersionHistory/save",//新增版本
    FileVersionHistoryUpdateById: "FileVersionHistory/updateById",//修改版本信息
    reorganizeSetOrder: "Reorganize/setOrder",//修改顺序号
    reorganizeArchive: "Reorganize/archive",//归档
    reorganizeKeyArchive: "Reorganize/keyArchive",//一键归档
    batchUpdate: "FileCollect/batchUpdate",//文件批量修改
    archiveTaskSave: "ArchiveTask/save",//添加归档任务
    archiveTaskList: "ArchiveTask/queryAllByLimit",//归档任务列表

    //文件权限管理
    authorityList: "FileReorganizationAuthority/queryAll",//根据文件id查询所有
    authorityById: "FileReorganizationAuthority/queryById",//根据文件id查询所有
    authoritySave: "FileReorganizationAuthority/save",//保存
    managementSave: "FileReorganizationAuthority/managementSave",//文档管理权限保存
    managementSave2: "FileReorganizationAuthority/managementSave2",//文档管理权限保存
    //文物清单
    culturalRelicsList: "CulturalRelics/queryAllByLimit",//列表
    queryById: "CulturalRelics/queryById",
    culturalRelicsSave: "CulturalRelics/save",//新增
    culturalRelicsUpdateById: "CulturalRelics/updateById",//修改
    culturalRelicsDeleteReduction: "CulturalRelics/deleteReduction",//还原
    culturalRelicsVersionReduction: "CulturalRelics/versionReduction",//还原
    culturalRelicsBatchUpdateById: "CulturalRelics/batchUpdateById",//批量修改
    queryAllByLimitRecycling: "CulturalRelics/queryAllByLimitRecycling",//回收站列表
    culturalRelicsRemoveById: "CulturalRelics/removeById",//删除
    culturalRelicsemptyRecycle: "CulturalRelics/emptyRecycle",//清空回收站
    culturalRelicsArchive: "CulturalRelics/archive",//归档
    culturalRelicsKeyArchive: "CulturalRelics/keyArchive",//一键归档
    culturalRelicsBack: "CulturalRelics/back",//退回
    culturalRelicsSetOrder: "CulturalRelics/setOrder",//改变顺序号
    culturalRelicsRetrieve: "CulturalRelics/queryAllByLimitRetrieve",//检索
    culturalRelicsByIdRetrieve: "CulturalRelics/queryByIdRetrieve",//检索详情
    culturalRelicsSeniorQuery: "CulturalRelics/seniorQuerySQL",//高级检索
    culturalRelicsImportExcel: "CulturalRelics/importExcel",//列表
    culturalRelicsExcelList: "CulturalRelics/listRedis",//查询临时列表数据
    culturalRelicsExcelSave: "CulturalRelics/saveRedis",//保存列表
    culturalRelicsQueryCount: "CulturalRelics/queryCount",//查询归档总数
    culturalRelicsBatchUpdate: "CulturalRelics/batchUpdate",//文件批量修改
    batchMobile: "CulturalRelics/batchMobile",//移动

    //清单版本
    culturalVersionHistoryList: "CulturalVersionHistory/queryAllByLimit",
    culturalVersionHistoryBatchInsert: "CulturalVersionHistory/batchInsert",//批量新增版本
    culturalVersionHistorySave: "CulturalVersionHistory/save",//新增版本
    culturalVersionHistoryUpdateById: "CulturalVersionHistory/updateById",//修改版本信息


    //档案管理
    queryExhibition: "FileManagement/queryExhibition",//展览
    queryExhibition2: "FileManagement/queryExhibition2",//展览
    queryExhibitionRecord: "FileManagement/queryExhibitionRecord",//展览
    queryAllByLimitManagement: "FileManagement/queryAllByLimit",//列表
    back: "FileManagement/back",//退回
    queryAllByLimitRetrieve: "FileManagement/queryAllByLimitRetrieve",//档案检索
    queryByIdRetrieve: "FileManagement/queryByIdRetrieve",//检索详情
    seniorQuerySQL: "FileManagement/seniorQuerySQL",//高级检索


    //标索管理
    labelIndexList: "LabelIndex/queryAllByLimit",//列表
    labelIndexUpdateById: "LabelIndex/updateById",//修改
    queryAllFile: "LabelIndex/queryAllFile",//标签档案
    queryAllFileListing: "LabelIndex/queryAllFileListing",//标签清单
    labelIndexSave: "LabelIndex/save",//新增标签
    labelIndexQueryAll: "LabelIndex/queryAll",//根据文件id查询所有标签
    labelIndexQueryAll2: "LabelIndex/queryAll2",//查询所有标签

    //我的档案
    queryAllByLimitMyFile: "MyFile/queryAllByLimitMyFile",//我的档案
    delete: "MyFile/delete",//删除
    ShareSave: "Share/save",//新增分享

    //我的收藏
    MyCollectionList: "MyCollection/queryAllByLimit",//列表查询
    MyCollectionRemoveById: "MyCollection/removeById",//删除
    MyCollectionSave: "MyCollection/save",//新增

    //分享
    ShareList: "Share/queryAllByLimit",//我的分享
    SharePavilion: "Share/queryAllPavilion",//馆内分享
    ShareDeleteById: "Share/deleteById",//我的分享：物理删除
    ShareUpdateById: "Share/updateById",//馆内分享：逻辑删除

    //我的借阅
    borrowingApplyList: "BorrowingApply/queryAllByLimit",//我的借阅
    queryAllByLimitApproval: "BorrowingApply/queryAllByLimitApproval",//我的审批
    borrowSave: "BorrowingApply/save",
    borrowBatchSave: "BorrowingApply/batchSave",
    borrowUpdateById: "BorrowingApply/updateById",

    //数据权限
    dataRoleList: "DataRole/queryAll",
    dataRoleSave: "DataRole/save",
    dataRoleUpdateById: "DataRole/updateById",
    dataRoleRemoveById: "DataRole/removeById",

    RoleDataruleList: "RoleDatarule/queryById",
    RoleDataruleSave: "RoleDatarule/save",

    //展览团队
    exhibitionTeamList: "ExhibitionTeam/list",//列表
    exhibitionTeamAll: "ExhibitionTeam/queryAllByLimit",//查询所有
    exhibitionTeamSave: "ExhibitionTeam/save",//保存
    exhibitionTeamRemoveById: "ExhibitionTeam/removeById",//删除
    exhibitionTeamCopy: "ExhibitionTeam/copy",//复制
    exhibitionTeamUpdateById: "ExhibitionTeam/updateById",//修改

    //目录模板数据
    TeamMemberList: "TeamMember/list",//列表
    TeamMemberSave: "TeamMember/save",//保存
    TeamMemberUpdate: "TeamMember/updateById",//修改
    TeamMemberRemove: "TeamMember/removeById",//删除

}

// 以上展览展示接口api


// 设置多源异构服务前缀zyd-zgww/
for (var s in sys) {
    sys[s] = 'zyd-zgww/sys' + sys[s];
}
for (var i in original) {
    original[i] = 'zyd-zgww/' + original[i];
}
for (var i in rcPage) {
    rcPage[i] = 'zyd-zgww/' + rcPage[i];
}
for (var i in resources) {
    resources[i] = 'zyd-zgww/' + resources[i];
}
for (var i in grouping) {
    grouping[i] = 'zyd-zgww/' + grouping[i];
}
for (var i in identification) {
    identification[i] = 'zyd-zgww/' + identification[i];
}
for (var i in dataMatching) {
    dataMatching[i] = 'zyd-zgww/' + dataMatching[i];
}
for (var i in uploadCommon) {
    uploadCommon[i] = 'zyd-zgww/' + uploadCommon[i];
}

// 设置展览展示服务前缀zyd-catalogue/
for (var i in extension) {
    extension[i] = 'zyd-catalogue/extension' + extension[i];
}
for (var i in flowable) {
    flowable[i] = 'zyd-catalogue/flowable' + flowable[i];
}
for (var i in mana) {
    mana[i] = 'zyd-catalogue/' + mana[i];
}
for (var i in catalogue) {
    catalogue[i] = 'zyd-catalogue/catalogue' + catalogue[i];
}
for (var i in docsearch) {
    docsearch[i] = 'zyd-catalogue/docsearch' + docsearch[i];
}
for (var i in report) {
    report[i] = 'zyd-catalogue/report' + report[i];
}
for (var i in infor) {
    infor[i] = 'zyd-catalogue/infor' + infor[i];
}
for (var c in common) {
    common[c] = 'zyd-catalogue/common' + common[c];
}

export default {
    infor,
    report,
    docsearch,
    catalogue,
    mana,
    flowable,
    extension,
    installation,
    digitalProducts,
    feedback,
    registrationMethod,
    authority,
    activitymanagement,
    activity,
    website,
    order,
    auth,
    sys,
    common,
    original,
    rcPage,
    resources,
    grouping,
    identification,
    assembly,
    dataMatching,
    uploadCommon,
    record,
    collection,
    digital,
}
