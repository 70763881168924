export default {
  namespaced: true,
  state: {
    userInfo: JSON.parse(sessionStorage.getItem("userInfo"))
},
  mutations: {
    updateUser (state, user) {
      state.userInfo = user
      sessionStorage.setItem('userInfo', JSON.stringify(user))
    }
  }
}
